import React from 'react'
import { Col, Form, Row } from '../lib/ui'
import { WalletSettings } from '../lib/models'
import strings from '../strings'
import NumberInput from '../components/NumberInput'

export default function PaymentSettings(props: {
  value: WalletSettings
  onChange: (newValue: WalletSettings) => void
}) {

  return <React.Fragment>
    <Form.Group className="mb-3 d-flex justify-content-between">
      <Form.Label>{strings.settings.commentAllowed}</Form.Label>
      <Form.Check id="commentAllowed" type="switch"
        defaultChecked={props.value.commentAllowed === undefined || props.value.commentAllowed}
        onChange={e => props.onChange({ ...props.value, commentAllowed: e.target.checked })} />
    </Form.Group>

    <Form.Group className="mb-3">
      <Form.Label>{strings.settings.defaultComment}</Form.Label>
      <Form.Control id="defaultComment" type="text"
        defaultValue={props.value.defaultComment ?? 'Payment'}
        onChange={e => props.onChange({ ...props.value, defaultComment: e.target.value.length > 0 ? e.target.value : undefined })} />
    </Form.Group>

    <Row>
      <Col>
        <NumberInput
          id="minimumAmount"
          label={strings.settings.minimumAmountLabel}
          value={toSatoshi(props.value.minimumAmount)}
          onValidate={value => isErrorValueMin(fromSatoshi(value)) ? strings.settings.minimumAmountInvalid : null}
          onChange={newValue => props.onChange({ ...props.value, minimumAmount: fromSatoshi(newValue) })} />
      </Col>
      <Col>
        <NumberInput
          id="maximumAmount"
          label={strings.settings.maximumAmountLabel}
          value={toSatoshi(props.value.maximumAmount)}
          onValidate={value => isErrorValueMax(fromSatoshi(value)) ? strings.settings.maximumAmountInvalid : null}
          onChange={newValue => props.onChange({ ...props.value, maximumAmount: fromSatoshi(newValue) })} />
      </Col>
    </Row>

  </React.Fragment>

  function isErrorValueMin(value: number | undefined): boolean {
    if (value === undefined || props.value.maximumAmount === undefined) return false
    return value < 1_000 || value > (props.value.maximumAmount || 1_000_000_000)
  }

  function isErrorValueMax(value: number | undefined): boolean {
    if (value === undefined || props.value.minimumAmount === undefined) return false
    return value < (props.value.minimumAmount || 1_000) || value > 1_000_000_000
  }
}

function toSatoshi(value: number | undefined): number | undefined {
  return value === undefined ? undefined : value / 1000
}

function fromSatoshi(value: number | undefined): number | undefined {
  return value === undefined ? undefined : value * 1000
}