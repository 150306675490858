import strings from '../strings'
import { CurrencyConverter, Event, TransferEvent, WalletEvent } from './models'

export function isCompletedTransfer(event: Readonly<Event>): event is TransferEvent {
  return isTransfer(event) && (event.status == null || event.status === 'succeeded' || event.status === 'failed')
}

export function isSuccessfulTransfer(event: Readonly<Event>): event is TransferEvent {
  return isTransfer(event) && (event.status == null || event.status === 'succeeded')
}

export function isFailedTransfer(event: Readonly<Event>): event is Readonly<TransferEvent> {
  return isTransfer(event) && event.status === 'failed'
}

export function isTransfer(event: Readonly<Event>): event is TransferEvent {
  return event.type === 'TransferEvent'
}

export function isPayment(event: Readonly<Event>): event is TransferEvent {
  return isTransfer(event) && event.amount > 0
}

export function isWithdrawal(event: Readonly<Event>): event is TransferEvent {
  return isTransfer(event) && event.amount < 0
}

/**
 * Get Rounded amount in the same currency
 */
export function getRoundedAmount(converter: CurrencyConverter, amount: number) {
  return converter.fromCurrency(converter.toCurrency(amount))
}

/**
 * Get Events Payment Balance
 */
export function getReceivedAmount(events: Readonly<Event[]>): number {
  return events.reduce((sum, e) => {
    if (isSuccessfulTransfer(e) && isPayment(e)) {
      sum += e.amount
    }
    return sum
  }, 0)
}

/**
 * Get Events Withdrawal Balance
 */
export function getSentAmount(events: Readonly<WalletEvent[]>): number {
  return events.reduce((sum, e) => {
    if (isSuccessfulTransfer(e) && isWithdrawal(e)) {
      sum += e.amount
    }
    return sum
  }, 0)
}

export function getTransferComment(event: TransferEvent): string | null {
  const comment = event.comment?.trim() ?? ''

  if (isFailedTransfer(event)) {
    if (comment.length === 0) {
      return `${strings._.failed}`
    } else {
      return `${strings._.failed} ${comment}`
    }
  }
  else if (isWithdrawal(event)) {
    return comment ?? strings._.withdrawn
  }

  return comment
}

const WALLET_URI_SCHEME = 'wallet://'

export function tryGetTransferPeerName(event: TransferEvent): string | null {
  if (typeof event.uri !== 'string' || !event.uri.startsWith(WALLET_URI_SCHEME)) {
    return null
  }

  const name = new URL(event.uri).searchParams.get('name')
  return name
}
