import {
    format,
    isToday, isYesterday, isThisMonth, isThisYear, isThisWeek as isThisWeek_,
    startOfWeek, lastDayOfWeek,
    add, sub,
    compareAsc, Duration, endOfMonth, endOfWeek, startOfMonth, startOfYear, endOfYear,
} from 'date-fns'
import { enUS, fr, ru, id } from 'date-fns/locale'
export { Duration }

export enum TimePeriod {
    Day,
    Week,
    Month,
    Year,
}

/** Today October 15, 2021 */
export function formatDateLong(dateTime: Date, language?: string): string {
    return format(dateTime, 'y', { locale: getLocale(language) })
}

/** 1453 */
export function formatYYYY(dateTime: Date, language?: string): string {
    return format(dateTime, 'y', { locale: getLocale(language) })
}

/** April, 1453 */
export function formatMMMMYYYY(dateTime: Date, language?: string): string {
    return format(dateTime, 'MMMM, y', { locale: getLocale(language) })
}

/** Apr 29, 1453 */
export function formatMMMDDYYYY(dateTime: Date, language?: string): string {
    return format(dateTime, 'PP', { locale: getLocale(language) })
}

/** 10 - 29 */
export function formatMMDD(dateTime: Date, language?: string): string {
    return format(dateTime, 'M - d', { locale: getLocale(language) })
}

/** April 29th, 1453 */
export function formatMMMMDDYYYY(dateTime: Date, language?: string): string {
    return format(dateTime, 'PPP', { locale: getLocale(language) })
}

/** Friday, April 29th, 1453 */
export function formatDDDDMMMMYYYY(dateTime: Date, language?: string): string {
    return format(dateTime, 'PPPP', { locale: getLocale(language) })
}

/** 10:06 PM */
export function formatTimeLong(dateTime: Date, language?: string): string {
    return format(dateTime, 'p', { locale: getLocale(language) })
}

/** Fri */
export function formatDDD(dateTime: Date, language?: string): string {
    return format(dateTime, 'EEE', { locale: getLocale(language) })
}

/** 1 */
export function formatD(dateTime: Date, language?: string): string {
    return format(dateTime, 'd', { locale: getLocale(language) })
}

/** Jan */
export function formatMMM(dateTime: Date, language?: string): string {
    return format(dateTime, 'LLL', { locale: getLocale(language) })
}

/** Fri 29, 10:06 PM */
export function formatDateTimeLong(dateTime: Date, language?: string): string {
    return format(dateTime, 'iii d, p', { locale: getLocale(language) })
}

/** Apr 29 */
export function formatMMMDD(dateTime: Date, language?: string): string {
    return format(dateTime, 'LLL d', { locale: getLocale(language) })
}

/** Apr 19 - Apr 25 */
export function formatWeek(dateTime: Date, language?: string): string {
    const firstDayWeek = startOfWeek(dateTime, { locale: getLocale(language) })
    const lastDayWeek = lastDayOfWeek(dateTime, { locale: getLocale(language) })

    const start = format(firstDayWeek, 'dd MMM', { locale: getLocale(language) })
    const end = format(lastDayWeek, 'dd MMM', { locale: getLocale(language) })

    return `${start} - ${end}`
}

export { isToday, isYesterday, isThisMonth, isThisYear }

export function isThisWeek(dateTime: Date, language?: string): boolean {
    return isThisWeek_(dateTime, { locale: getLocale(language) })
}

export function addDays(date: Date, day: number): Date {
    return add(date, { days: day, })
}

export function addDuration(date: Date, duration: Duration): Date {
    return add(date, duration)
}

export function substractDuration(date: Date, duration: Duration): Date {
    return sub(date, duration)
}

export function isGreaterOrEqual(left: Date, right: Date): boolean {
    return compareAsc(left, right) >= 0
}

export type TimeRange = {
    start: string
    end: string
}

export type GetTimeRange = (date: Date) => TimeRange

export function getRange(date: Date, numDaysToAdd: number): TimeRange {
    if (numDaysToAdd > 0)
        return { start: date.toISOString(), end: addDays(date, numDaysToAdd).toISOString() }
    return { start: addDays(date, numDaysToAdd).toISOString(), end: date.toISOString() }
}

export function getThisWeek(date: Date): TimeRange {
    return { start: startOfWeek(date).toISOString(), end: endOfWeek(date).toISOString() }
}

export function getThisMonth(date: Date): TimeRange {
    return { start: startOfMonth(date).toISOString(), end: endOfMonth(date).toISOString() }
}

export function getThisYear(date: Date): TimeRange {
    return { start: startOfYear(date).toISOString(), end: endOfYear(date).toISOString() }
}

function getLocale(language?: string): Locale {
    switch (language) {
        case 'fr':
            return fr
        case 'en':
            return enUS
        case 'id':
            return id
        case 'ru':
            return ru
        default:
            return enUS
    }
}
