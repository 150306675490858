import React from 'react'
import { WalletActivitySummary } from '../components/WalletActivitySummary'
import PaymentsChart from '../components/PaymentsChart'
import { Stack } from '../lib/ui'
import useWallet from '../hooks/useWallet'

export default function HomePage() {
  const wallet = useWallet()

  return (
    <Stack className='text-center'>
      <WalletActivitySummary events={wallet.events} />
      <PaymentsChart events={wallet.events} theme='dark' />
    </Stack>
  )
}