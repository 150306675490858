import React, { ReactNode } from 'react'

export default function PageContent(props: { className?: string; children: React.PropsWithChildren<ReactNode> }) {
    return (
        <div className={`pt-4 ${props.className}`} style={styles.pageContent}>
            {props.children}
        </div>
    )
}

export function PageContentSave(props: React.PropsWithChildren<ReactNode>) {
    return (
        <div className="pt-4" style={styles.pageContent}>
            {props.children}
        </div>
    )
}

const styles: { [key: string]: React.CSSProperties } = {
    pageContent: {
        width: '100%',
        maxWidth: '400px',
        textAlign: 'start',
        paddingBottom: '100px'
    }
}

