import React from 'react'
import { Row, Col } from '../lib/ui'
import { TransferEvent, WalletEvent } from '../lib/models'
import CurrencyAmountText from './CurrencyAmountText'
import * as dt from '../lib/dt'
import strings from '../strings'
import { TimePeriod } from '../lib/dt'
import { useLanguage } from '../hooks/useWallet'
import { getTransferComment, isCompletedTransfer, tryGetTransferPeerName } from '../lib/wallet'

export type TimeFormatter = (s: string) => string;
export type GroupFactory = (events: TransferEvent[]) => EventGroup[];

/**
 * Wallet events complete activity.
 */
export default function WalletEventList(props: { events: WalletEvent[], timePeriod: TimePeriod }) {
    const language = useLanguage()

    const events = props.events.filter(isCompletedTransfer)
    events.reverse()

    const groupFactory: GroupFactory = events => getPeriodGroups(props.timePeriod, events, language)
    const groups = groupFactory(events)

    return (
        <div className="pt-3 px-2">
            {groups.map(g => <WalletEventGroup key={g.name} group={g} />)}
        </div >
    )
}

/**
 * WalletEventGroup
 */
function WalletEventGroup(props: { group: EventGroup }) {
    return (
        <div className="px-2">
            <div className="pb-2">
                {props.group.name}
            </div>
            <ul className="list-group list-group-flush ps-1">
                {props.group.events.map(e => <WalletEventItem key={e.transactionId + e.status} event={e} timeFormatter={props.group.timeFormatter} />)}
            </ul>
            <hr />
        </div>
    )
}

/**
 * WalletEventItem
 */
function WalletEventItem({ event }: {
    event: TransferEvent
    timeFormatter?: TimeFormatter
}) {
    const comment = getTransferComment(event)
    const peer = tryGetTransferPeerName(event)
    const peerText = !!peer ? <span className="small fst-italic fw-light"> {peer}</span> : null

    return (
        <Row>
            <Col className="text-muted text-wrap">{comment}{peerText}</Col>
            <Col xs="auto">
                <CurrencyAmountText amount={event.amount} />
            </Col>
        </Row>
    )
}

function getPeriodGroups(period: TimePeriod, events: TransferEvent[], language?: string): EventGroup[] {
    const keyProvider = getKeyProvider(period, language)
    const timeFormatter = getTimeFormatter(period, language)

    const groupedEvents = groupBy(keyProvider, events)
    const keysArray = Array.from(groupedEvents.keys())
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const groups = keysArray.map(k => ({ name: k, timeFormatter, events: groupedEvents.get(k)! }))
    return groups
}

interface EventGroup {
    name: string
    events: TransferEvent[]
    timeFormatter: TimeFormatter
}

function groupBy<T>(keyProvider: (item: T) => string, items: T[]): Map<string, T[]> {
    const map = items.reduce((previous, e) => {
        const key = keyProvider(e)
        const group = previous.get(key)
        if (group === undefined) {
            previous.set(key, [e])
        } else {
            group.push(e)
        }
        return previous
    }, new Map<string, T[]>())

    return map
}

function getKeyProvider(timePeriod: TimePeriod, language?: string): (walletEvent: TransferEvent) => string {
    switch (timePeriod) {
        case TimePeriod.Day:
            return e => dayKeyProvider(e, language)
        case TimePeriod.Week:
            return e => weekKeyProvider(e, language)
        case TimePeriod.Month:
            return e => monthKeyProvider(e, language)
        case TimePeriod.Year:
            return e => yearKeyProvider(e, language)
        default:
            throw new Error('summaryPeriod is not recognize')
    }
}

function getTimeFormatter(timePeriod: TimePeriod, language?: string): TimeFormatter {
    switch (timePeriod) {
        case TimePeriod.Day:
            return v => formatDayTime(v, language)
        case TimePeriod.Week:
            return v => formatWeekTime(v, language)
        case TimePeriod.Month:
            return v => formatMonthTime(v, language)
        case TimePeriod.Year:
            return v => formatYearTime(v, language)
        default:
            throw new Error('summaryPeriod is not recognize')
    }
}

function formatDayTime(value: string, language?: string): string {
    return dt.formatTimeLong(new Date(value), language)
}

function formatWeekTime(value: string, language?: string): string {
    return dt.formatDateTimeLong(new Date(value), language)
}

function formatMonthTime(value: string, language?: string): string {
    return dt.formatDateTimeLong(new Date(value), language)
}

function formatYearTime(value: string, language?: string): string {
    return dt.formatMMMDDYYYY(new Date(value), language)
}

function dayKeyProvider(walletEvent: WalletEvent, language?: string): string {
    const dateTime = new Date(walletEvent.time)

    if (dt.isToday(dateTime)) {
        return strings.dt.today
    }
    else if (dt.isYesterday(dateTime)) {
        return strings.dt.yesterday
    }
    else {
        return dt.formatMMMDDYYYY(dateTime, language)
    }
}

function weekKeyProvider(walletEvent: WalletEvent, language?: string): string {
    const dateTime = new Date(walletEvent.time)

    if (dt.isThisWeek(dateTime)) {
        return strings.dt.thisWeek
    }
    else if (dt.isThisYear(dateTime)) {
        return dt.formatWeek(dateTime, language)
    }
    else {
        return `${dt.formatYYYY(dateTime)}: ${dt.formatWeek(dateTime, language)}`
    }
}

function monthKeyProvider(walletEvent: WalletEvent, language?: string): string {
    const dateTime = new Date(walletEvent.time)

    if (dt.isThisMonth(dateTime)) {
        return strings.dt.thisMonth
    }
    else {
        return dt.formatMMMMYYYY(dateTime, language)
    }
}

function yearKeyProvider(walletEvent: WalletEvent, language?: string): string {
    const dateTime = new Date(walletEvent.time)

    if (dt.isThisYear(dateTime)) {
        return strings.dt.thisYear
    } else {
        return dt.formatYYYY(dateTime, language)
    }
}

