import React from 'react'
import { TimePeriod } from '../lib/dt'
import { Button, ButtonGroup } from '../lib/ui'
import strings from '../strings'

export default function TimePeriodSelector(props: {
    value: TimePeriod
    onChange: (timeRange: TimePeriod) => void
}) {

    const trs = getTimePeriods()
    return (
        <ButtonGroup aria-label="Time Period Selection">
            {trs.map(
                tr =>
                    <Button
                        key={tr}
                        onClick={() => props.onChange(getTimePeriod(tr))}
                        size='sm'
                        style={isSelected(props.value, tr) ? styles.selectedButtonStyle : styles.buttonStyle}
                        className={`mx-2`}
                    >
                        {getLocalePeriodString(tr)}
                    </Button>)}
        </ButtonGroup>
    )
}

/** Get string[] of enum TimeRange */
function getTimePeriods(): string[] {
    return Object.keys(TimePeriod).filter((s) => { return isNaN(Number(s)) })
}

function getTimePeriod(timeRange: string): TimePeriod {
    return TimePeriod[timeRange]
}

function isSelected(selectedTimeRange: TimePeriod, timeRange: string): boolean {
    if (TimePeriod[timeRange] === selectedTimeRange) {
        return true
    } else {
        return false
    }
}

function getLocalePeriodString(period: string): string {
    const periodEnum = TimePeriod[period]

    switch (periodEnum) {
        case TimePeriod.Day:
            return strings.dt.day
        case TimePeriod.Week:
            return strings.dt.week
        case TimePeriod.Month:
            return strings.dt.month
        case TimePeriod.Year:
            return strings.dt.year
        default:
            return ''
    }
}

const styles: { [key: string]: React.CSSProperties } = {
    buttonStyle: {
        height: 'min-content',
        backgroundColor: '#ffffff20',
        color: '#ffffff70',
        borderWidth: 0
    },
    selectedButtonStyle: {
        height: 'min-content',
        backgroundColor: '#ffffff50',
        color: '#ffffff',
        borderWidth: 0
    }
}