import Localization from 'react-localization'
import { useReadLocalStorage } from '../hooks/useLocalStorage'
import { Wallet } from '../lib/models'
import de from './de.json'
import en from './en.json'
import fr from './fr.json'
import id from './id.json'
import it from './it.json'
import ru from './ru.json'
const strings = new Localization({ en, ru, fr, it, id, de })

// Get language from settings
const locale = useReadLocalStorage<string>('locale')
const wallet = useReadLocalStorage<Wallet>('wallet')
strings.setLanguage(wallet?.settings?.language ?? locale ?? 'en')
export default strings