{
  "_": {
    "amount": "Amount",
    "home": "Home",
    "balance": "Balance",
    "cancel": "Cancel",
    "connecting": "Connecting...",
    "copy": "Copy",
    "copied": "Copied",
    "comment": "Comment",
    "commentPlaceholder": "Comment (optional)",
    "confirmation": "Confirmation",
    "failed": "Failed",
    "activity": "Activity",
    "next": "Next",
    "back": "Back",
    "name": "Name",
    "noService": "Service unavailable",
    "ok": "OK",
    "open": "Open",
    "offline": "Offline",
    "receive": "Receive",
    "received": "Received",
    "required": "Required",
    "save": "Save",
    "saved": "Saved",
    "send": "Send",
    "sent": "Sent",
    "sentTo": "Sent to",
    "settings": "Settings",
    "success": "Success",
    "withdraw": "Withdraw",
    "withdrawn": "Withdrawn"
  },
  "welcome": {
    "getStarted": "Get started",
    "step1Title": "Making payment awesome with LN Pay",
    "step1Content": "Use your Phoenix or Breez lightning wallet to login, get paid and withdraw funds.",
    "step2Title": "Anonymous login with LNURL through lightning wallet apps",
    "step2Content": "No email or personal information needed.",
    "step3Title": "Share your QR code with the world",
    "step3Content": "Print QR code on paper to display at your place, send in email or place an image on your website or social media app - easy as that!",
    "step4Content": "Collect payments and withdraw easily to your lightning wallet",
    "step4Title": "Any lightning wallet supporting LNPay works, such as Phoenix or Breez wallet.",
    "letsGo": "Let's go!"
  },
  "login": {
    "login": "Login",
    "text1": "Open your lightning wallet app (Phoenix, Breez, etc...)",
    "text2": "Scan or copy QR to login anonymously",
    "text3": "Any lightning wallet supporting LNURL-Auth will work."
  },
  "send": {
    "destination": "To",
    "destinationPlaceholder": "LNPay username",
    "remember": "Remember \"to\" and \"amount\" for future"
  },
  "receive": {
    "text1": "Share this QR code or your Lightning address with your peers",
    "text2": "They scan it and send you payments from their lightning wallet apps",
    "text3": "Print QR code on paper to display at your store, send in email or place an image on your website or social media - easy!",
    "setAddress": "Set Lightning Address",
    "shareButton": "Share",
    "shareTitle": "LNPay QR Code",
    "shareText": "Here is my LNPay QR Code"
  },
  "withdraw": {
    "availableAmount": "Available amount",
    "revealQrCode": "Reveal QR code",
    "text1": "Scan this QR code with your lightning wallet app",
    "text2": "Withdraw your funds. BOOM!",
    "text3": "Make sure to be in a private environment before revealing the QR code. Anyone can withdraw your funds with this QR code."
  },
  "settings": {
    "minimumAmountInvalid": "Must be between 1 and maximum.",
    "maximumAmountInvalid": "Must be between minimum and 1,000,000.",
    "minimumAmountLabel": "Minimum amount (sat)",
    "maximumAmountLabel": "Maximum amount (sat)",
    "receivePaymentsAt": "Receive payments at",
    "defaultComment": "Default comment",
    "commentAllowed": "Enable comments",
    "language": "Language",
    "currency": "Currency",
    "enableNotifications": "Enable notifications",
    "disableNotifications": "Disable notifications"
  },
  "dt": {
    "today": "Today",
    "yesterday": "Yesterday",
    "thisWeek": "This week",
    "thisMonth": "This month",
    "thisYear": "This year",
    "day": "Day",
    "week": "Week",
    "month": "Month",
    "year": "Year",
    "since": "Since"
  },
  "errors": {
    "TransferAmountTooSmall": "Amount too small",
    "NameInvalid": "Invalid name",
    "NameAlreadyExists": "Name already exists"
  }
}