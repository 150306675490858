{
  "_": {
    "amount": "Montant",
    "home": "Accueil",
    "balance": "Balance",
    "cancel": "Annuler",
    "connecting": "Connexion en cours...",
    "copy": "Copier",
    "copied": "Copié",
    "comment": "Commentaire",
    "commentPlaceholder": "Commentaire (facultatif)",
    "confirmation": "Confirmation",
    "failed": "Échoué",
    "activity": "Activité",
    "next": "Suivant",
    "back": "Retour",
    "name": "Nom",
    "noService": "Service indisponible",
    "ok": "OK",
    "open": "Ouvrir",
    "offline": "Hors ligne",
    "receive": "Recevoir",
    "received": "Recu",
    "required": "Obligatoire",
    "save": "Enregistrer",
    "saved": "Enregistré",
    "send": "Envoyer",
    "sent": "Envoyé",
    "sentTo": "Envoyé à",
    "settings": "Paramètres",
    "success": "Succès",
    "withdraw": "Retirer",
    "withdrawn": "Retiré"
  },
  "welcome": {
    "getStarted": "Commencer",
    "step1Title": "Recevoir des paiements Lightning facilement avec LN Pay",
    "step1Content": "Utilisez votre portefeuille Phoenix ou Breez pour vous connecter, être payé et retirer des fonds.",
    "step2Title": "Connexion anonyme avec LNURL-AUTH depuis votre portefeuille Lightning",
    "step2Content": "Aucun email ou informations personnelles nécessaires.",
    "step3Title": "Partagez votre code QR avec le monde",
    "step3Content": "Imprimez le code QR sur papier et afficher, envoyez-vous un email ou placez une image sur votre site Web ou votre application Social Media - Facile.",
    "step4Content": "Recevoir des paiements et retirer facilement dans votre portefeuille Lighnting",
    "step4Title": "Tout portefeuille Lightning supportant LNURL, telles que Phoenix ou Breez.",
    "letsGo": "Allons-y!"
  },
  "login": {
    "login": "Connexion",
    "text1": "Ouvrez votre application Lightning (Phoenix, Breez, etc.)",
    "text2": "Numériser ou copier QR pour vous connecter anonymement",
    "text3": "Tout application Lightning supportant LNURL-AUTH fonctionnera."
  },
  "send": {
    "destination": "Destination",
    "destinationPlaceholder": "Nom d'utilisateur lnpay",
    "remember": "Se rappeller"
  },
  "receive": {
    "text1": "Partagez ce code QR ou votre adresse Lightning avec vos pairs",
    "text2": "Ils le scannent et vous envoient des paiements avec leurs portefeuille Lightning",
    "text3": "Imprimez le code QR sur papier et afficher dans votre magasin, envoyez un email ou placez une image sur votre site Web ou votre média social - Facile!",
    "setAddress": "Lightning Address",
    "shareButton": "Partager",
    "shareTitle": "LNPay QR Code",
    "shareText": "Voici mon code QR LNPAY"
  },
  "withdraw": {
    "availableAmount": "Montant disponible",
    "revealQrCode": "Révéler QR code",
    "text1": "Scannez ce code QR avec votre application Lightning Wallet",
    "text2": "Retirer vos fonds. BOOM!",
    "text3": "Assurez-vous d'être dans un environnement privé avant de révéler le code QR. Tout le monde peut retirer vos fonds avec ce code QR."
  },
  "settings": {
    "minimumAmountInvalid": "Doit être compris entre 1 et maximum.",
    "maximumAmountInvalid": "Doit être compris entre minimum et 1.000.000.",
    "minimumAmountLabel": "Paiement minimum (sat)",
    "maximumAmountLabel": "Paiement maximum (sat)",
    "receivePaymentsAt": "Recevoir des paiements à",
    "defaultComment": "Description par défaut",
    "commentAllowed": "Commentaire autorisé",
    "language": "Langue",
    "currency": "Monnaie",
    "enableNotifications": "Activer les notifications",
    "disableNotifications": "Désactiver les notifications"
  },
  "dt": {
    "today": "Aujourd'hui",
    "yesterday": "Hier",
    "thisWeek": "Cette semaine",
    "thisMonth": "Mois en cours",
    "thisYear": "Année en cours",
    "day": "Jour",
    "week": "Semaine",
    "month": "Mois",
    "year": "Année",
    "since": "Depuis le"
  },
  "errors": {
    "TransferAmountTooSmall": "Transfert trop petit",
    "NameInvalid": "Nom incorrect",
    "NameAlreadyExists": "Le nom existe déjà"
  }
}