import React from 'react'
import { ExclamationTriangleIcon } from '../components/icons'
import { Button, Modal } from '../lib/ui'
import strings from '../strings'

export interface AsyncAction<Request> {
  status: ActionStatus
  start: (request: Request) => void
  dialog?: JSX.Element
}

export enum ActionStatus {
  Created,
  Confirm,
  Pending,
  Succeeded,
  Failed,
}

export default function useAsyncAction<Request, Response>(props: {
  action: (request: Request) => Promise<Response>
  confirm: (request: Request) => JSX.Element
  success: (request: Request, response: Response) => JSX.Element
  error: (request: Request, error: Error) => JSX.Element
}): AsyncAction<Request> {
  const initialState = React.useMemo<ActionState>(() => ({ status: ActionStatus.Created, }), [])
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const onCancel = React.useCallback(() => dispatch({ type: ActionType.Cancel }), [])
  const onConfirm = React.useCallback(() => dispatch({ type: ActionType.Confirm }), [])
  const start = React.useCallback((request: Request) => dispatch({
    type: ActionType.Start,
    action: () => props.action(request).then(response => {
      dispatch({
        type: ActionType.Succeed,
        dialog: <SuccessDialog content={props.success(request, response)} onClose={() => dispatch({ type: ActionType.Reset })} />,
      })
    }, error => {
      dispatch({
        type: ActionType.Fail,
        dialog: <ErrorDialog content={props.error(request, error)} onClose={() => dispatch({ type: ActionType.Reset })} />,
      })
    }),
    dialog: <ConfirmDialog content={props.confirm(request)} onConfirm={onConfirm} onCancel={onCancel} />,
  }), [props])

  return {
    status: state.status,
    start,
    dialog: state.dialog,
  }
}

function ConfirmDialog(props: {
  content: JSX.Element
  onConfirm: () => void
  onCancel: () => void
}) {
  return <Modal contentClassName="border-0 bg-dark justify-content-center text-center" centered show={true} >
    <Modal.Header className="bg-primary border-0">
      <div className="fs-3 w-100 text-dark">{strings._.confirmation}</div>
    </Modal.Header>
    <Modal.Body>
      {props.content}
    </Modal.Body>
    <Modal.Footer className="border-0">
      <Button onClick={props.onCancel} className="w-25" variant="secondary">
        {strings._.cancel}
      </Button>
      <Button onClick={props.onConfirm} className="w-25" variant="primary">
        {strings._.ok}
      </Button>
    </Modal.Footer>
  </Modal>
}

function SuccessDialog(props: {
  content: JSX.Element
  onClose: () => void
}) {
  return <Modal contentClassName="border-0 bg-dark justify-content-center text-center" centered show={true} >
    <Modal.Header className="bg-success border-0">
      <div className="fs-3 w-100">{strings._.success}</div>
    </Modal.Header>
    <Modal.Body>
      {props.content}
    </Modal.Body>
    <Modal.Footer className="border-0 bg-dark justify-content-center text-center">
      <Button onClick={props.onClose} className="w-50" variant="primary">
        {strings._.ok}
      </Button>
    </Modal.Footer>
  </Modal>
}

function ErrorDialog(props: {
  content: JSX.Element
  onClose: () => void
}) {
  return <Modal contentClassName='border-0 bg-dark justify-content-center text-center' centered show={true} >
    <Modal.Header className="bg-danger border-0">
        <ExclamationTriangleIcon width={48} height={48} />
        <div className="fs-3 w-100">{strings._.failed}</div>
    </Modal.Header>
    <Modal.Body>
      {props.content}
    </Modal.Body>
    <Modal.Footer className="border-0 justify-content-center">
      <Button onClick={props.onClose} className="w-50" variant="primary">
        {strings._.ok}
      </Button>
    </Modal.Footer>
  </Modal>
}
interface BaseActionState {
  status: ActionStatus
  dialog?: JSX.Element
}

interface CreatedState extends BaseActionState {
  status: ActionStatus
}

interface ConfirmState extends BaseActionState {
  status: ActionStatus.Confirm
  dialog: JSX.Element
  action: () => void
}

interface PendingState extends BaseActionState {
  status: ActionStatus.Pending
}

interface SuccessState extends BaseActionState {
  status: ActionStatus.Succeeded
}

interface FailedState extends BaseActionState {
  status: ActionStatus.Failed
}

type ActionState =
  CreatedState |
  ConfirmState |
  PendingState |
  SuccessState |
  FailedState

enum ActionType {
  Start,
  Cancel,
  Confirm,
  Succeed,
  Fail,
  Reset,
}

interface Action {
  type: ActionType
}

interface StartAction extends Action {
  type: ActionType.Start
  action: () => void
  dialog: JSX.Element
}

interface ConfirmAction extends Action {
  type: ActionType.Confirm
}

interface CancelAction extends Action {
  type: ActionType.Cancel
}

interface SucceedAction extends Action {
  type: ActionType.Succeed
  dialog: JSX.Element
}

interface FailAction extends Action {
  type: ActionType.Fail
  dialog: JSX.Element
}

interface ResetAction extends Action {
  type: ActionType.Reset
}

type ReducerAction =
  StartAction |
  ConfirmAction |
  CancelAction |
  SucceedAction |
  FailAction | ResetAction

function reducer(state: ActionState, action: ReducerAction): ActionState {
  switch (action.type) {

    case ActionType.Start:
      const newState: ConfirmState = {
        status: ActionStatus.Confirm,
        dialog: action.dialog,
        action: action.action,
      }
      return newState

    case ActionType.Cancel:
      return { status: ActionStatus.Created }

    case ActionType.Confirm:
      if (state.status !== ActionStatus.Confirm) {
        throw new Error(`Invalid state`)
      }
      (state as ConfirmState).action()
      return { status: ActionStatus.Pending }

    case ActionType.Succeed:
      return { status: ActionStatus.Succeeded, dialog: action.dialog }

    case ActionType.Fail:
      return { status: ActionStatus.Failed, dialog: action.dialog }

    case ActionType.Reset:
      return { status: ActionStatus.Created }

    default:
      throw new Error(`Unknown action: ${JSON.stringify(action)}`)
  }
}