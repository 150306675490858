import React from 'react'
import { useAsyncValue } from '../hooks/hooks'
import LnUrl from '../components/LnUrl'
import strings from '../strings'
import { useCurrencyConverter } from '../hooks/useExchange'
import useNotice from '../hooks/useNotice'
import { Container } from '../lib/ui'
import useWallet from '../hooks/useWallet'

export default function WithdrawPage() {
  const wallet = useWallet()
  const url = useAsyncValue<string>(wallet.getWithdrawUrl, [wallet.connected])

  const balance = useCurrencyConverter().format(wallet.balance)

  const extra = (
    <div className="mb-md-5">
      <small className="fw-bold">{strings.withdraw.availableAmount}</small>
      <h2 className="text-primary mb-3 mb-md-4">{balance}</h2>
      <ol className="text-start fw-bold">
        <li>{strings.withdraw.text1}</li>
        <li>{strings.withdraw.text2}</li>
      </ol>
    </div>)

  return useNotice() ?? (
    <Container>
      {url.value &&
        <LnUrl url={url.value} extra={extra}
          privacyWarning={strings.withdraw.text3} />
      }
    </Container>
  )
}
