import React from 'react'

import Home from '../images/view-dashboard.svg'
import Activity from '../images/view-list-outline.svg'
import Receive from '../images/qrcode-scan.svg'
import Send from '../images/send.svg'
import Withdraw from '../images/withdraw.svg'
import Settings from '../images/cog-outline.svg'
import Share from '../images/share-variant.svg'
import Download from '../images/arrow-collapse-down.svg'

export type IconProps = React.SVGProps<SVGSVGElement>;

export function LightningIcon(props: IconProps) {
  return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={props.color ?? 'yellow'} className="bi bi-lightning" viewBox="0 0 16 16" >
    <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5zM6.374 1 4.168 8.5H7.5a.5.5 0 0 1 .478.647L6.78 13.04 11.478 7H8a.5.5 0 0 1-.474-.658L9.306 1H6.374z" />
  </svg>
}

export const ClipboardIcon = (props: IconProps) => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16" {...props}>
  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
</svg>

export const ArrowUpRightIcon = (props: IconProps) => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16" {...props}>
  <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
  <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
</svg>

export const ExitIcon = (props: IconProps) => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16" {...props}>
  <path d="M7.5 1v7h1V1h-1z" />
  <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
</svg>

export const ExclamationTriangleIcon = (props: IconProps) => <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? 24} height={props.height ?? 24} fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16" {...props}>
  <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
  <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
</svg>

export const EyeSlashIcon = (props: IconProps) => <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? 24} height={props.height ?? 24} fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
  <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
  <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
  <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
</svg>

export const EyeIcon = (props: IconProps) => <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? 24} height={props.height ?? 24} fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
</svg>

export const WifiOffIcon = (props: IconProps) => <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? 24} height={props.height ?? 24} fill={props.fill ?? 'currentColor'} className="bi bi-wifi-off" viewBox="0 0 16 16" {...props}>
  <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z" />
</svg>


/*
   https://materialdesignicons.com/icon/view-dashboard  Advanced export -> View SVG -> SVG file
*/
export const HomeIcon = (props: IconProps) => (
  <Home fill="currentColor" {...props} />
)
/*
   https://materialdesignicons.com/icon/view-list-outline  Advanced export -> View SVG -> SVG file
*/
export const ActivityIcon = (props: IconProps) => (
  <Activity fill="currentColor" viewBox="3 3 19 19" {...props} />
)
/*
   https://materialdesignicons.com/icon/qrcode-scan Advanced export -> View SVG -> SVG file
*/
export const ReceiveIcon = (props: IconProps) => (
  <Receive fill="currentColor" {...props} />
)

/*
   https://materialdesignicons.com/icon/send Advanced export -> View SVG -> SVG file
*/
export const SendIcon = (props: IconProps) => (
  <Send fill="currentColor" {...props} />
)

/*
   custom icon (note: design icon in black color so the fill is not set in SVG)
*/
export const WithdrawIcon = (props: IconProps) => (
  <Withdraw fill="currentColor" {...props} />
)

/*
   https://materialdesignicons.com/icon/cog-outline Advanced export -> View SVG -> SVG file
*/
export const SettingsIcon = (props: IconProps) => (
  <Settings fill="currentColor" {...props} />
)

/*
   https://materialdesignicons.com/icon/share-variant Advanced export -> View SVG -> SVG file
*/
export const ShareIcon = (props: IconProps) => (
  <Share fill="currentColor" width={16} height={16} viewBox="2 4 20 16" {...props} />
)

/*
   https://materialdesignicons.com/icon/arrow-collapse-down Advanced export -> View SVG -> SVG file
*/
export const DownloadIcon = (props: IconProps) => (
  <Download fill="currentColor" width={16} height={16} viewBox="2 4 20 16" {...props} />
)

