import React, { useCallback, useEffect, useRef } from 'react'

/*
https://usehooks-typescript.com/react-hook/use-timeout
 */
export function useTimeout(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the timeout.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return
    }

    const id = setTimeout(() => savedCallback.current(), delay)

    return () => clearTimeout(id)
  }, [delay])
}

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef(callback)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export type AsyncValue<T> = {
  readonly hasValue: boolean
  readonly isPending: boolean
  readonly value?: T | null | undefined
  readonly error?: Error
  readonly setValue: (value: T | null | undefined) => void
}

export function useAsyncValue<T>(asyncFunc: () => Promise<T>, dependencies?: React.DependencyList): AsyncValue<T> {
  const setValue = useCallback((value: T | null | undefined) => {
    setState(s => ({ ...s, hasValue: value !== undefined, value, error: undefined, isPending: false }))
  }, [])
  const [state, setState] = React.useState<AsyncValue<T>>({ hasValue: false, isPending: true, setValue })
  const cancelled = useRef<boolean>(false)
  useEffect(() => {
    const action = async () => {
      cancelled.current = false
      try {
        const result = await asyncFunc()
        if (cancelled.current) return
        setState({ hasValue: true, isPending: false, value: result, setValue })
      } catch (error) {
        if (cancelled.current) return
        setState({ hasValue: false, isPending: false, error, setValue })
      }
    }

    action()

    return () => {
      cancelled.current = true
    }
  }, dependencies)
  return state
}

export type AsyncAction<T> = {
  run: () => Promise<T | null>
  hasValue: boolean
  isPending: boolean
  value?: T
  error?: Error
}

// TODO: redo this function
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useAsync<T>(fn: () => Promise<T>, _dependencies?: React.DependencyList): AsyncAction<T> {
  const run = async () => {
    if (state.isPending) throw new Error('shit')
    setState({ hasValue: false, isPending: true, run })
    try {
      const value = await fn()
      setState({ hasValue: true, isPending: false, run, value })
      return value
    } catch (error) {
      setState({ hasValue: false, isPending: false, run, error })
      return null
    }
  }

  const [state, setState] = React.useState<AsyncAction<T>>({ hasValue: false, isPending: false, run })

  return { ...state, run }
}