import React from 'react'
import { useCurrencies } from '../hooks/useExchange'
import { Form } from '../lib/ui'
import strings from '../strings'

export default function CurrencySelect(props: {
    value: string | undefined
    onChange: (value: string) => void
}) {
    const currencies = [...useCurrencies()].sort((a, b) => a.code.localeCompare(b.code))

    return (
        <Form.Group className="mb-3">
            <Form.Label>{strings.settings.currency}</Form.Label>
            <Form.Select
                id="currency"
                size="sm"
                defaultValue={props.value ?? 'SAT'}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.onChange(e.target.value)}>
                {currencies.map(c => (<option key={c.code}>{c.code}</option>))}
            </Form.Select>
        </Form.Group >
    )
}