{
  "_": {
    "amount": "Betrag",
    "home": "Home",
    "balance": "Kontostand",
    "cancel": "Abbrechen",
    "connecting": "Verbinden...",
    "copy": "Kopieren",
    "copied": "Kopiert",
    "comment": "Kommentar",
    "commentPlaceholder": "Kommentar (optional)",
    "confirmation": "Bestätigung",
    "failed": "Gescheitert",
    "activity": "Aktivität",
    "next": "Nächste",
    "back": "Der Rücken",
    "name": "Name",
    "noService": "Dienst nicht verfügbar",
    "ok": "Ok",
    "open": "Öffnen",
    "offline": "Offline",
    "receive": "Empfangen",
    "received": "Erhalten ",
    "required": "Erforderlich",
    "save": "Speichern",
    "saved": "Gespeichert",
    "send": "Senden",
    "sent": "Gesendet",
    "sentTo": "Gesendet an",
    "settings": "Einstellungen",
    "success": "Erfolg",
    "withdraw": "Abheben",
    "withdrawn": "Abgehoben"
  },
  "welcome": {
    "getStarted": "Loslegen",
    "step1Title": "Bezahlung mit LNPay",
    "step1Content": "Verwenden Sie Ihre Phoenix- oder Breez Lightning Wallet, um sich anzumelden, zu bezahlen und Geld abzuheben.",
    "step2Title": "Anonymer Login mit LNURL durch Lightning Wallet Apps",
    "step2Content": "Keine E-Mail oder persönliche Informationen erforderlich.",
    "step3Title": "Teilen Sie Ihren QR-Code mit der Welt",
    "step3Content": "Drucken Sie den QR-Code aus um ihn an Ihrem Platz anzuzeigen, E-Mail senden oder ein Bild auf Ihrer Website oder in der Social Media-App zu platzieren - ganz einfach!",
    "step4Content": "Sammeln Sie Zahlungen und heben Sie diese einfach in Ihre Lightning Wallet ab",
    "step4Title": "Jede Lightning Wallet, die LNPay unterstützt, funktioniert wie\r\ndie Phoenix oder Breez Wallet.",
    "letsGo": "Lass uns gehen!"
  },
  "login": {
    "login": "Anmeldung",
    "text1": "Öffnen Sie Ihre Lightning Wallet-App (Phoenix, Breez usw.)",
    "text2": "Scannen oder kopieren Sie den QR Code, um sich anonym anzumelden",
    "text3": "Jede Lightning Geldbörse, welche LNURL-AUTH unterstützt, funktioniert."
  },
  "send": {
    "destination": "Ziel",
    "destinationPlaceholder": "Lnpay Benutzername",
    "remember": "Denken Sie an \"und\" Betrag \"für die Zukunft"
  },
  "receive": {
    "text1": "Teilen Sie diesen QR-Code oder Ihre Lightning Adresse mit Ihren Kollegen",
    "text2": "Scannen Sie den Code und senden so Ihne Zahlungen von ihren Lightning Wallet App",
    "text3": "Drucken Sie den QR Code und legen Sie ihn im Geschäft aus, senden Sie ihn per E-Mail oder zeigen Sie ihn als Bild auf Ihrer Webseite oder auf Social Media - ganz einfach.",
    "setAddress": "Lightning Address",
    "shareButton": "Teilen",
    "shareTitle": "LNPay QR Code",
    "shareText": "Hier ist mein lnpay QR -Code"
  },
  "withdraw": {
    "availableAmount": "Verfügbare Summe",
    "revealQrCode": "QR-Code anzeigen",
    "text1": "Scannen Sie diesen QR-Code mit Ihrer Lightning Wallet",
    "text2": "Heben sie Ihr Guthaben ab. BOOM!",
    "text3": "Stellen Sie sicher, dass Sie sich in einem privaten Umfeld befinden, bevor Sie den QR-Code anzeigen. Jeder kann Ihr Guthaben mit diesem QR-Code abheben."
  },
  "settings": {
    "minimumAmountInvalid": "Muss zwischen 1 und Maximum liegen.",
    "maximumAmountInvalid": "Muss zwischen Minimum und 1.000.000 liegen.",
    "minimumAmountLabel": "Mindestbetrag (SAT)",
    "maximumAmountLabel": "Maximalbetrag (SAT)",
    "receivePaymentsAt": "Zahlungen erhalten an",
    "defaultComment": "Standardkommentar",
    "commentAllowed": "Kommentare aktivieren",
    "language": "Sprache",
    "currency": "Währung",
    "enableNotifications": "Benachrichtigungen einschalten",
    "disableNotifications": "Benachrichtigungen ausschalten"
  },
  "dt": {
    "today": "Heute",
    "yesterday": "Gestern",
    "thisWeek": "Diese Woche",
    "thisMonth": "Dieser Monat",
    "thisYear": "Dieses Jahr",
    "day": "Tag",
    "week": "Woche",
    "month": "Monat",
    "year": "Jahr",
    "since": "Seit"
  },
  "errors": {
    "TransferAmountTooSmall": "Betrag zu klein",
    "NameInvalid": "Ungültiger Name",
    "NameAlreadyExists": "Name existiert bereits"
  }
}