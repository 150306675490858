import React from 'react'
import { Button, Navbar, Nav, Col, Stack, Spinner } from '../lib/ui'
import { ExitIcon, WifiOffIcon } from './icons'
import Logo from '../images/logo.svg'
import useWallet from '../hooks/useWallet'

export default function PageHeader(props: { title: string; }) {
  const wallet = useWallet()

  return (

    <Navbar variant="dark" className="sticky-top w-100 justify-content-between" bg="dark">

      <Col>
        <Stack direction="horizontal">
          <Navbar.Brand className="ps-4">
            {!wallet.connected && !wallet.error && <Spinner className="m-1" animation="grow" variant="primary" size="sm" />}
            {!wallet.connected && !!wallet.error && <WifiOffIcon color="orange" />}
            {wallet.connected && !wallet.error && <Logo width={20} height={30} viewBox="0 0 50 100" />}
          </Navbar.Brand>
        </Stack>
      </Col>

      <Col className="d-flex justify-content-center" >
        <Nav.Item>
          <h1>{props.title}</h1>
        </Nav.Item>
      </Col>

      <Col className="d-flex justify-content-end">
        <Nav.Item className="pe-4">
          <Button id="close" hidden={!wallet.opened} variant="muted" size="sm" onClick={wallet.close}><ExitIcon /></Button>
        </Nav.Item>
      </Col>

    </Navbar >
  )
}
