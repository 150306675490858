import React from 'react'
import { useAsyncValue } from '../hooks/hooks'
import LnUrl from '../components/LnUrl'
import strings from '../strings'
import useNotice from '../hooks/useNotice'
import { Container } from '../lib/ui'
import useWallet from '../hooks/useWallet'

export default function OpenPage() {
  const wallet = useWallet()
  const url = useAsyncValue<string>(async () => {
    if (!wallet.connected)
      return ''
    return await wallet.getAuthorizeUrl()
  }, [wallet.connected])

  const extra = (
    <div className="text-start">
      <ol className="fw-bold">
        <li>{strings.login.text1}</li>
        <li>{strings.login.text2}</li>
      </ol>
      <p className="text-wrap text-muted">{strings.login.text3}</p>
    </div>)

  return useNotice() ?? (
    <Container>
      {(url.hasValue && !!url.value && url.value.length > 0) && <LnUrl url={url.value ?? ''} extra={extra} />}
    </Container>
  )
}
