import React from 'react'
import WalletEventList from '../components/WalletEventList'
import TimePeriodSelector from '../components/TimePeriodSelector'
import Container from 'react-bootstrap/esm/Container'
import useLocalStorage from '../hooks/useLocalStorage'
import { TimePeriod } from '../lib/dt'
import useWallet from '../hooks/useWallet'

export default function ActivityPage() {
  const [period, setPeriod] = useLocalStorage('activityTimePeriod', TimePeriod.Week)

  const wallet = useWallet()

  return (
    <React.Fragment>
      <Container className="d-flex justify-content-center">
        <TimePeriodSelector value={period} onChange={setPeriod} />
      </Container>
      <WalletEventList events={wallet.events} timePeriod={period} />
    </React.Fragment>
  )
}