import React from 'react'
import { Col, Row } from '../lib/ui'
import { WalletEvent } from '../lib/models'
import strings from '../strings'
import CurrencyAmountText from './CurrencyAmountText'
import { useCurrency, useCurrencyConverter } from '../hooks/useExchange'
import { getReceivedAmount, getRoundedAmount, getSentAmount } from '../lib/wallet'

export function WalletActivitySummary(props: { events: WalletEvent[]; }) {
  const converter = useCurrencyConverter()
  const currency = useCurrency()
  const received = getReceivedAmount(props.events)
  const sent = getSentAmount(props.events)
  const balance = getRoundedAmount(converter, received) + getRoundedAmount(converter, sent)

  return (
    <React.Fragment>
      <Row className="rounded d-flex text-nowrap py-2 m-2 mb-4 flex-nowrap justify-content-evenly">
        <Col>
          <AmountBlock name={strings._.balance} amount={balance} />
          {currency.code !== 'SAT' && <CurrencyAmountText amount={balance} currencyCode='SAT' />}
        </Col>
      </Row>
      <hr />
    </React.Fragment>
  )
}

function AmountBlock(props: { name: string; amount: number; }) {
  const classNameColor = props.amount > 0 ? 'text-primary' : 'text-secondary'

  return (
    <div className="text-center p-0">
      {props.name}
      <div>
        <CurrencyAmountText amount={props.amount} className={classNameColor + ' fw-bold'} />
      </div>
    </div>
  )
}
