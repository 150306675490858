import React, { useMemo, useEffect } from 'react'
import { CurrencyFormatter, formatCurrency } from '../lib/currency'
import { Currency, CurrencyConverter, ExchangeRate, ExchangeRateMap } from '../lib/models'
import useLocalStorage from './useLocalStorage'
import useWallet, { useCurrencyCode, useWalletLocale } from './useWallet'

const SATOSHI_CURRENCY: Currency = { code: 'SAT', symbol: 's', name: 'Satoshi', decimals: 0 }
const SATOSHI_RATE: ExchangeRate = { provider: 'Satoshi', time: '2009-01-03T18:15:05Z', value: 1 }
const INITIALE_EXCHANGE_STATE: Readonly<ExchangeState> = {
    time: '2000-01-01',
    currencies: [SATOSHI_CURRENCY],
    rates: { 'SAT': SATOSHI_RATE }
}

type ExchangeState = {
    readonly time: string
    readonly rates: ExchangeRateMap
    readonly currencies: Readonly<Currency[]>
}

interface Exchange extends ExchangeState {
    readonly currencyConverter?: CurrencyConverter
}

export const ExchangeContext = React.createContext<Exchange>(INITIALE_EXCHANGE_STATE)

export function ExchangeProvider({ children }: React.PropsWithChildren<unknown>) {
    const [state, setState] = useLocalStorage('exchange', INITIALE_EXCHANGE_STATE)
    if (!state.currencies) { setState(INITIALE_EXCHANGE_STATE) } // TODO: remove this line,(migration only)
    const wallet = useWallet()

    useEffect(() => {
        if (!wallet || !wallet.opened || !wallet.connected) return

        wallet.getCurrencies().then(currencies => {
            console.debug('updated currencies')
            setState(s => ({ ...s, currencies: [...currencies].sort((a, b) => a.code.localeCompare(b.code)) }))
        })

        wallet.getExchangeRates().then(r => {
            console.debug('updated rates')
            setState(s => ({ ...s, ...r }))
        })
    }, [wallet.opened, wallet.connected])

    const currencyCode = wallet.settings.currencyCode ?? 'SAT'

    const currencyConverter = React.useMemo(() => createCurrencyConverter({
        currencyCode: isRateAvailable(state, currencyCode) ? currencyCode : 'SAT',
        locale: wallet.settings.language,
        exchange: state
    }), [state, wallet.settings.currencyCode, wallet.settings.language])

    return (
        <ExchangeContext.Provider value={{ ...state, currencyConverter }}>
            {children}
        </ExchangeContext.Provider>
    )
}

function useExchange(): Readonly<ExchangeState> {
    return React.useContext(ExchangeContext)
}

export function useCurrencies(): Readonly<Currency[]> {
    return useExchange().currencies
}

export function useCurrency(currencyCode?: string): Readonly<Currency> {
    currencyCode ??= useCurrencyCode()
    const currencies = useCurrencies()
    const currency = currencies.find(c => c.code === currencyCode) ?? SATOSHI_CURRENCY
    if (isRateAvailable(useExchange(), currency.code))
        return currency
    return SATOSHI_CURRENCY
}

export function useCurrencyFormatter(currency?: Readonly<Currency>): CurrencyFormatter {
    const locale = useWalletLocale()
    currency ??= useCurrency()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const formatter = useMemo<CurrencyFormatter>(() => getCurrencyFormatter(currency!, locale), [currency, locale])
    return formatter
}

function getCurrencyFormatter(currency: Readonly<Currency>, locale?: string): CurrencyFormatter {
    return (value, options) => formatCurrency(currency, value, locale, options)
}

export type CurrencyConverter2 = {
    fromCurrency: (value: number, currencyCode: string | undefined) => number
    toCurrency: (value: number, currencyCode: string | undefined) => number
}

export function useCurrencyConverter2(): CurrencyConverter2 {
    const exchange = useExchange()

    return React.useMemo(() => {
        return {
            toCurrency: (value: number, currencyCode: string | undefined) => {
                return value / 1000 / exchange.rates[currencyCode ?? 'SAT'].value
            },
            fromCurrency: (value: number, currencyCode: string | undefined) => {
                return Math.floor(value * 1000 * exchange.rates[currencyCode ?? 'SAT'].value)
            },
        }
    }, [exchange.rates])
}

export function useCurrencyConverter(): CurrencyConverter {
    const value = React.useContext(ExchangeContext).currencyConverter
    if (!value) throw new Error('CurrencyConverter is not initialized')
    return value
}

function createCurrencyConverter(props: {
    exchange: ExchangeState
    currencyCode: string
    locale: string | undefined
}): CurrencyConverter {
    const { exchange, currencyCode, locale } = props
    const { currencies, rates } = exchange
    const currency = currencies.find(c => c.code === currencyCode) ?? SATOSHI_CURRENCY
    const rate = rates[currency.code].value

    const toCurrency = (value: number) => value / 1000 / rate
    const fromCurrency = (value: number) => value * 1000 * rate
    const format: CurrencyFormatter = (value, options) => formatCurrency(currency, toCurrency(value), locale, options)

    return {
        toCurrency,
        fromCurrency,
        format,
    }
}

function isRateAvailable(exchange: ExchangeState, currencyCode: string | undefined): boolean {
    if (!currencyCode) {
        return false
    }
    return !!exchange.currencies.find(c => c.code === currencyCode) && !!exchange.rates[currencyCode]
}