{
  "_": {
    "amount": "Сумма",
    "home": "Главная",
    "balance": "Баланс",
    "cancel": "Отменить",
    "connecting": "Соединение...",
    "copy": "Скопировать",
    "copied": "Скопировано",
    "comment": "Описание",
    "commentPlaceholder": "Описание (необязательнo)",
    "confirmation": "Подтверждение",
    "failed": "Случилась ошибка",
    "activity": "История",
    "next": "Дальше",
    "back": "Назад",
    "name": "Имя",
    "noService": "Сервис недоступен",
    "ok": "OK",
    "open": "Открыть",
    "offline": "Не в сети",
    "receive": "Получить",
    "received": "Получено",
    "required": "Необходимое",
    "save": "Сохранить",
    "saved": "Сохранено",
    "send": "Отправить",
    "sent": "Отправлено",
    "sentTo": "Отправлено",
    "settings": "Настройки",
    "success": "Удача",
    "withdraw": "Снять",
    "withdrawn": "Снято"
  },
  "welcome": {
    "getStarted": "Начать",
    "step1Title": "Делаем платежи классными с LN Pay",
    "step1Content": "Используйте свой Lightning кошелек Phoenix или Breez для входа в систему, получения оплаты и снятия денег",
    "step2Title": "Анонимный вход с LNURL через приложения Lightning Wallet",
    "step2Content": "Не требуется электронная почта или личная информация",
    "step3Title": "Поделитесь своим QR-кодом со всем миром",
    "step3Content": "Распечатайте QR-код на бумаге разместить в вашем магазине, отправьте по электронной почте или разместите изображение на своем веб-сайте или в социальных сетях - легко!",
    "step4Content": "Собирайте платежи и легко снимайте их на свой Lightning кошелек",
    "step4Title": "Работает любой Lightning кошелек, поддерживающий LNPay, например кошелек Phoenix или Breez",
    "letsGo": "Приступим!"
  },
  "login": {
    "login": "Вход",
    "text1": "Откройте приложение Lightning Wallet (Phoenix, Breez и т.д.)",
    "text2": "Просканируйте или скопируйте QR-код для анонимного входа",
    "text3": "Подойдет любой кошелек Lightning с поддержкой LNURL-Auth"
  },
  "send": {
    "destination": "Кому",
    "destinationPlaceholder": "Lnpay имя пользователя",
    "remember": "Запомнить \"кому\" и \"сумму\" на будущее"
  },
  "receive": {
    "text1": "Поделитесь этим QR-кодом или Lightning-адресом с друзьями",
    "text2": "Они могут сканировать его и отправлять вам платежи из своих приложений Lightning Wallet",
    "text3": "Распечатайте на бумаге, отправьте по электронной почте или разместите изображение на своем веб-сайте или в соц сетях - легко!",
    "setAddress": "Lightning Address",
    "shareButton": "Поделиться",
    "shareTitle": "LNPay QR-код",
    "shareText": "Вот мой LNPay QR-код"
  },
  "withdraw": {
    "availableAmount": "Доступная Сумма",
    "revealQrCode": "Раскрыть QR-код",
    "text1": "Просканируйте этот QR-код с помощью приложения Lightning Wallet",
    "text2": "Снимайте свои деньги. И всё!",
    "text3": "Перед тем, как раскрыть QR-код, убедитесь, что находитесь в приватной обстановке. Любой желающий может снять ваши деньги с помощью этого QR-кода."
  },
  "settings": {
    "minimumAmountInvalid": "Должно быть от 100 до максимального.",
    "maximumAmountInvalid": "Должно быть от минимум до 1.000.000.",
    "minimumAmountLabel": "Минимальная сумма (sat)",
    "maximumAmountLabel": "Максимальная сумма (sat)",
    "receivePaymentsAt": "Получать платежи на",
    "defaultComment": "Описание по умолчанию для ваших платежей",
    "commentAllowed": "Разрешить комментарий к платежу",
    "language": "Язык",
    "currency": "Валюта",
    "enableNotifications": "Включить уведомления",
    "disableNotifications": "Отключить уведомления"
  },
  "dt": {
    "today": "Сегодня",
    "yesterday": "Вчера",
    "thisWeek": "На этой неделе",
    "thisMonth": "Этот месяц",
    "thisYear": "Этот год",
    "day": "День",
    "week": "Неделя",
    "month": "Месяц",
    "year": "Год",
    "since": "С"
  },
  "errors": {
    "TransferAmountTooSmall": "Сумма слишком мала",
    "NameInvalid": "Неправильное имя",
    "NameAlreadyExists": "Имя Алмеди существует"
  }
}