import React from 'react'
import { useAsync } from '../hooks/hooks'
import { Button, Col, Container, Form, Row } from '../lib/ui'
import strings from '../strings'
import useNotice from '../hooks/useNotice'
import useToast from '../hooks/useToast'
import useWallet, { useWalletLocale } from '../hooks/useWallet'
import PaymentSettings from '../components/PaymentSettings'
import CurrencySelect from '../components/CurrencySelect'
import useLocale from '../hooks/useLocale'
import { getLnAddress, isValidName } from '../lib/lnpay'
import { useNotificationManager } from '../lib/notification'

export default function SettingsPage() {
  const wallet = useWallet()
  const [settings, setSettings] = React.useState(wallet.settings)
  const lnAddress = getLnAddress(settings.name != '' ? settings.name : strings._.name)
  const toast = useToast()
  const [, setLocale] = useLocale()
  const walletLocale = useWalletLocale()

  const save = useAsync(async () => {
    try {
      await wallet.saveSettings(settings)
      if (settings.language != null && walletLocale) {
        setLocale(settings.language)
      }
      toast.addToast(strings._.saved)
    } catch (e) {
      toast.addToast(e.message)
    }
  }, [settings])

  // TODO: investigate why the reference equality check is not working...
  const isDirty = JSON.stringify(settings) != JSON.stringify(wallet.settings)
  return useNotice() ?? (
    <React.Fragment>
      <Container className="px-3">
        <Form.Group className="mb-3">
          <Form.Label>{strings._.name}</Form.Label>
          <Form.Control id="name" type="text" placeholder={strings._.name}
            isInvalid={!isValidName(settings.name)}
            defaultValue={settings.name}
            onChange={e => setSettings(s => ({ ...s, name: e.target.value }))} />
          <Form.Control.Feedback type="invalid">{strings.errors.NameInvalid}</Form.Control.Feedback>
          <Form.Text muted>
            {strings.settings.receivePaymentsAt} <span className="fst-italic fw-bolder">{lnAddress}</span>
          </Form.Text>
        </Form.Group>

        <Row>
          <Col>
            <CurrencySelect value={settings.currencyCode} onChange={newValue => setSettings(s => ({ ...s, currencyCode: newValue }))} />
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>{strings.settings.language}</Form.Label>
              <Form.Select id="language" size="sm" defaultValue={walletLocale}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSettings(s => ({ ...s, language: e.target.value }))}>
                {strings.getAvailableLanguages().sort().map(l => (<option key={l}>{l}</option>))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <NotificationToggle />

        <PaymentSettings value={settings} onChange={(newValue) => setSettings({ ...settings, ...newValue })} />

        <div className='pt-1'> </div>
        <hr />
        <Container className="d-flex justify-content-center">
          <Button id="save" disabled={!isDirty || save.isPending} onClick={save.run}>{strings._.save}</Button>
        </Container>
      </Container>
    </React.Fragment>
  )
}


function NotificationToggle() {
  const notificationManager = useNotificationManager()
  const toggleNotification = useAsync(notificationManager.toggleNotification)

  return (
    <Form.Group className="mt-3 py-2 d-flex justify-content-between">
      <Form.Label>{strings.settings.enableNotifications}</Form.Label>
      <Form.Check id="toggleNotification" type="switch"
        hidden={!notificationManager.isSupported}
        disabled={toggleNotification.isPending}
        checked={notificationManager.isEnabled}
        onChange={toggleNotification.run} />
    </Form.Group>)
}

