import React from 'react'
import { Form, InputGroup } from '../lib/ui'
import RecentList from './RecentList'
import { Currency } from '../lib/models'
import { CurrencyAmount, serializeCurrencyAmount, deserializeCurrencyAmount } from '../lib/currency'
import strings from '../strings'

export default function CurrencyInput(props: {
  id?: string
  label?: string
  disabled?: boolean
  requiredLabel?: boolean
  value: CurrencyAmount
  onChange: (value: CurrencyAmount) => void
  maximumValue: string
  currencies: ReadonlyArray<Currency>
  mru: ReadonlyArray<string>
}) {
  const onChangeAmount = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value.length === 0 ? null : parseFloat(e.target.value)
    props.onChange({ ...props.value, amount })
  }, [props])

  const onChangeCurrency = React.useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const currencyCode = e.target.value
    props.onChange({ ...props.value, currencyCode })
  }, [props])

  const isInvalid = (props.value.amount ?? 0) <= 0

  return (
    <Form.Group className="mb-3">
      <Form.Label className='d-flex justify-content-between'>
        {props.label}
      </Form.Label>

      <div className="d-flex">
        <Form.Control
          id={props.id}
          disabled={props.disabled}
          // isInvalid={isInvalid}
          type="number"
          placeholder={props.label}
          value={props.value.amount ?? ''}
          onChange={onChangeAmount}
        />

        <Form.Select
          id="currency"
          disabled={props.disabled}
          size="sm"
          style={styles.select}
          value={props.value.currencyCode ?? 'SAT'}
          onChange={onChangeCurrency}>
          {props.currencies.map(c => (<option key={c.code}>{c.code}</option>))}
        </Form.Select>
      </div>

      {!!props.mru && <RecentList
        values={props.mru}
        value={serializeCurrencyAmount(props.value)}
        onChange={v => props.onChange(deserializeCurrencyAmount(v))} />}

    </Form.Group>
  )
}

const styles: { [key: string]: React.CSSProperties } = {
  select: {
    width: '30%',
    marginLeft: 10
  }
}