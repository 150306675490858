import React, { CSSProperties } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom'
import ActivityPage from './pages/ActivityPage'
import { useAsyncValue } from './hooks/hooks'
import useWallet, { WalletProvider } from './hooks/useWallet'
import HomePage from './pages/HomePage'
import ReceivePage from './pages/ReceivePage'
import WithdrawPage from './pages/WithdrawPage'
import OpenPage from './pages/OpenPage'
import NavigationBar from './components/NavigationBar'
import SettingsPage from './pages/SettingsPage'
import PageHeader from './components/PageHeader'
import PageContent from './components/PageContent'
import strings from './strings'
import { ExchangeProvider } from './hooks/useExchange'
import useNotice from './hooks/useNotice'
import { ToastProvider } from './hooks/useToast'
import SendPage from './pages/SendPage'
import useEventMonitor from './hooks/useEventMonitor'
import { ModalProvider } from './hooks/useModal'
import { PwaProvider } from './hooks/usaPwa'
import useLocale, { LocaleProvider } from './hooks/useLocale'

export default function App() {
  return (
    <LocaleProvider>
      <PwaProvider>
        <WalletProvider>
          <ExchangeProvider>
            <Router>
              <ToastProvider>
                <ModalProvider>
                  <LnPayApp />
                </ModalProvider>
              </ToastProvider>
            </Router>
          </ExchangeProvider>
        </WalletProvider>
      </PwaProvider>
    </LocaleProvider>
  )
}

function LnPayApp() {
  useLocale()
  const wallet = useWallet()
  const isReady = wallet.connected || wallet.error
  useEventMonitor()
  //console.debug('LnPayApp', locale, strings.getLanguage(), strings.getInterfaceLanguage(), wallet?.settings.language)
  return (<React.Fragment>
    <div className="text-xs-device page-wrapper" style={styles.page}>
      <Switch>
        <Route path="/open" >
          {isReady && wallet.opened && wallet.balance > 0 && <Redirect to="/activity" />}
          {isReady && wallet.opened && wallet.balance === 0 && <Redirect to="/receive" />}
          <PageHeader title={strings.login.login} />
          <PageContent className="flex-grow-1 d-flex">
            <OpenPage />
          </PageContent>
        </Route>
        <Route path="/home">
          {isReady && !wallet.opened && <Redirect to="/open" />}
          <PageHeader title={strings._.home} />
          <PageContent>
            <HomePage />
            <NavigationBar />
          </PageContent>
        </Route>
        <Route path="/receive">
          {isReady && !wallet.opened && <Redirect to="/open" />}
          <PageHeader title={strings._.receive} />
          <PageContent className="flex-grow-1 d-flex">
            <ReceivePage />
            <NavigationBar />
          </PageContent>
        </Route>
        <Route path="/send">
          {isReady && !wallet.opened && <Redirect to="/open" />}
          <PageHeader title={strings._.send} />
          <PageContent>
            <SendPage />
            <NavigationBar />
          </PageContent>
        </Route>
        <Route path="/withdraw">
          {isReady && !wallet.opened && <Redirect to="/open" />}
          <PageHeader title={strings._.withdraw} />
          <PageContent className="flex-grow-1 d-flex">
            <WithdrawPage />
            <NavigationBar />
          </PageContent>
        </Route>
        <Route path="/activity">
          {isReady && !wallet.opened && <Redirect to="/open" />}
          <PageHeader title={strings._.activity} />
          <PageContent>
            <ActivityPage />
            <NavigationBar />
          </PageContent>
        </Route>
        <Route path="/settings">
          {isReady && !wallet.opened && <Redirect to="/open" />}
          <PageHeader title={strings._.settings} />
          <PageContent>
            <SettingsPage />
            <NavigationBar />
          </PageContent>
        </Route>
        <Route path="/">
          {isReady && wallet.opened && <Redirect to="/activity" />}
          {!wallet.opened && <WelcomePage />}
        </Route>
      </Switch>
    </div>
  </React.Fragment >)
}

function WelcomePage() {
  const page = useAsyncValue(async () => {
    const p = await import('./pages/WelcomePage')
    return p.default
  }, [])
  return page.value != null ? page.value() : useNotice()
}

const styles: Record<string, CSSProperties> = {
  page: {
    textAlign: 'center',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}
