import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav } from '../lib/ui'
import strings from '../strings'
import { ActivityIcon, ReceiveIcon, WithdrawIcon, SettingsIcon, HomeIcon, SendIcon } from './icons'

export default function NavigationBar() {
    return (
        <Navbar variant="dark" className="navbar-xs-device justify-content-center fixed-bottom text-nowrap p-0" bg="primary">
            <Nav>
                <NavItem title={strings._.home} linkContainerName="/home" icon={<HomeIcon height={24} />} />
                <NavItem title={strings._.activity} linkContainerName="/activity" icon={<ActivityIcon height={24} />} />
                <NavItem title={strings._.receive} linkContainerName="/receive" icon={<ReceiveIcon height={24} />} />
                <NavItem title={strings._.send} linkContainerName="/send" icon={<SendIcon height={24} />} />
                <NavItem title={strings._.withdraw} linkContainerName="/withdraw" icon={<WithdrawIcon height={24} />} />
                <NavItem title={strings._.settings} linkContainerName="/settings" icon={<SettingsIcon height={24} />} />
            </Nav>
        </Navbar>
    )
}

function NavItem(props: {
    title: string
    linkContainerName: string
    icon: JSX.Element
}) {
    return (
        <LinkContainer to={props.linkContainerName}>
            <Nav.Link>
                <div className="text-center">{props.icon}</div>
                <div>{props.title}</div>
            </Nav.Link>
        </LinkContainer>
    )
}