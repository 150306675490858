import React from 'react'
import strings from '../strings'
import Logo from '../images/logo.svg'
import { ChartData, StyledChart } from './StyledChart'
import CurrencyAmountText from './CurrencyAmountText'
import { Button, Row, Col, Container } from '../lib/ui'
import { TransferEvent, WalletEvent } from '../lib/models'
import * as dt from '../lib/dt'
import useLocalStorage from '../hooks/useLocalStorage'
import { useLanguage } from '../hooks/useWallet'
import { isPayment } from '../lib/wallet'

type Period = Readonly<{
  name: string
  getTimeRange: dt.GetTimeRange
  interval: dt.Duration
  labelFormatter: (date: Date, locale?: string) => string
  rangeFormatter: (date: Date, locale?: string) => string
}>

const PERIODS: Readonly<Period[]> = [
  { name: '7D', getTimeRange: ((date) => dt.getRange(date, -7)), interval: { days: 1 }, labelFormatter: dt.formatDDD, rangeFormatter: dt.formatMMMDD },
  { name: '1M', getTimeRange: ((date) => dt.getRange(date, -31)), interval: { days: 1 }, labelFormatter: dt.formatD, rangeFormatter: dt.formatMMMDD },
  { name: '3M', getTimeRange: ((date) => dt.getRange(date, -90)), interval: { months: 1 }, labelFormatter: dt.formatMMM, rangeFormatter: dt.formatMMMDD },
  { name: '6M', getTimeRange: ((date) => dt.getRange(date, -180)), interval: { months: 1 }, labelFormatter: dt.formatMMM, rangeFormatter: dt.formatMMMDD },
  { name: '1Y', getTimeRange: ((date) => dt.getRange(date, -365)), interval: { months: 1 }, labelFormatter: dt.formatMMM, rangeFormatter: dt.formatMMMDDYYYY },
]

export default function PaymentsChart(props: { events: WalletEvent[], theme: 'dark' | 'light' | undefined }) {
  const [periodIndex, setPeriodIndex] = useLocalStorage('homeTimePeriod', 0)
  const period = PERIODS[periodIndex]
  const language = useLanguage()
  const now = React.useRef(new Date())
  const timeRange = period.getTimeRange(now.current)
  const payments = React.useMemo(() => props.events?.filter(event => isPayment(event) && event.time >= timeRange.start && event.time <= timeRange.end) as TransferEvent[], [timeRange, props.events])
  const data = React.useMemo(() => getAmountsByPeriod(period, payments, language), [payments, period])

  return (
    <Container>
      <Row className="align-items-center pb-3 pt-3">
        <Col className='text-start'>
          <h6 className='text-muted mb-0'>{strings._.received}</h6>
        </Col>
        <Col xs="auto" className='text-end'>
          <PeriodSelector value={period} onChange={p => setPeriodIndex(PERIODS.indexOf(p))} theme={props.theme} />
        </Col>
      </Row>
      <div style={styles[props.theme ?? 'dark'].chartWrapper}>
        <ChartHeader period={period} payments={payments} theme={props.theme} />
        <StyledChart data={data} theme={props.theme} />
      </div>
    </Container>
  )
}

function PeriodSelector(props: {
  value: Period
  onChange: (period: Period) => void
  theme?: 'dark' | 'light'
}) {
  const { value: period, onChange } = props
  return <React.Fragment>{PERIODS.map(p => {
    return <Button key={p.name} size='sm' className="mx-2"
      style={period === p ? styles[props.theme ?? 'dark'].selectedButtonStyle : styles[props.theme ?? 'dark'].buttonStyle}
      onClick={() => onChange(p)}>{p.name}</Button>
  })}
  </React.Fragment >
}


function ChartHeader(props: { period: Period, payments: TransferEvent[], theme?: 'dark' | 'light' }) {
  const { payments } = props
  const language = useLanguage()

  let periodAmount = 0
  for (const key in payments) {
    periodAmount += payments[key].amount
  }

  const timeRange = props.period.getTimeRange(new Date())
  const startDate = new Date(timeRange.start)
  const endDate = new Date(timeRange.end)
  const formattedTimeRange = props.period.rangeFormatter(startDate, language) + ' - ' + props.period.rangeFormatter(endDate, language)

  return <div className='justify-content-between d-flex pt-2'>
    <div className='d-flex'>
      <Logo width={20} height={30} viewBox="0 0 50 100" className='mx-1' />
      <div className='pb-4 px-2' style={styles[props.theme ?? 'dark'].chartHeader}>
        <CurrencyAmountText amount={periodAmount} className='h6 m-0 d-flex lh-1' />
      </div>
    </div>
    <small className='text-muted mx-1'>{formattedTimeRange}</small>

  </div>
}

function getAmountsByPeriod(period: Period, payments: TransferEvent[], language?: string): ChartData {
  const buckets: { label: string, start: string, end: string, total: number | null }[] = []
  const timeRange = period.getTimeRange(new Date())
  const startDate = new Date(timeRange.start)
  const endDate = new Date(timeRange.end)

  // Initialize buckets
  for (let date = startDate; date < endDate; date = dt.addDuration(date, period.interval)) {
    const end = dt.addDuration(date, period.interval)
    buckets.push({
      label: period.labelFormatter(end, language),
      start: date.toISOString(),
      end: end.toISOString(),
      total: null,
    })
  }
  for (const payment of payments) {
    const p = buckets.find(p => payment.time >= p.start && payment.time < p.end)
    if (!!p) {
      p.total = (p.total ?? 0) + payment.amount
    }
  }

  const labels = buckets.map(b => b.label)
  const values = buckets.map(b => b.total)
  return { labels, values }
}

const styles: { [key: string]: { [key: string]: React.CSSProperties } } = {
  'dark': {
    chartHeader: {
      color: '#ffffff'
    },
    chartWrapper: {
      backgroundColor: '#444445',
      borderRadius: 15,
      padding: 5
    },
    buttonStyle: {
      height: 'min-content',
      backgroundColor: '#515052',
      color: '#9d9d99',
      borderWidth: 0
    },
    selectedButtonStyle: {
      height: 'min-content',
      backgroundColor: '#767677',
      color: '#ffffff',
      borderWidth: 0
    }
  },
  'light': {
    chartHeader: {
      color: '#000000'
    },
    chartWrapper: {
      backgroundColor: '#f7f7f7',
      borderRadius: 15,
      padding: 5
    },
    buttonStyle: {
      height: 'min-content',
      backgroundColor: '#dfdfdf',
      color: '#00000070',
      borderWidth: 0
    },
    selectedButtonStyle: {
      height: 'min-content',
      backgroundColor: '#afafaf',
      color: '#000000',
      borderWidth: 0
    }
  }
}