import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useAsyncValue } from '../hooks/hooks'
import LnUrl from '../components/LnUrl'
import strings from '../strings'
import { Container, Nav } from '../lib/ui'
import useWallet, { useLnAddress } from '../hooks/useWallet'

export default function ReceivePage() {
  const wallet = useWallet()
  const url = useAsyncValue<string>(wallet.getReceiveUrl, [wallet.connected])
  const lnAddress = useLnAddress()
  const extra = (
    <div className="text-start">
      <ol className="fw-bold w-100">
        <li>{strings.receive.text1}. {!lnAddress && <SettingsLink />}{lnAddress && <span className="fst-italic fw-bolder">{lnAddress}</span>}</li>
        <li>{strings.receive.text2}</li>
        {!!wallet.settings.name}
        {/* <p className="text-wrap text-muted fw-normal pt-2">{strings.receive.text3}</p> */}
      </ol>
    </div>)

  return (
    <Container>
      {url.value && <LnUrl url={url.value} extra={extra} buttons={{ share: true, copy: true, open: false, download: true }} />}
    </Container>
  )
}


function SettingsLink() {
  return <LinkContainer to={'/settings'}>
    <Nav.Link><span className="text-muted fst-itali">{strings.receive.setAddress}</span></Nav.Link>
  </LinkContainer>
}