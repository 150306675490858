import React from 'react'

export type ModalService = {
    showModal: (content: JSX.Element) => void
    hideModal: () => void
}

const ModalContext = React.createContext<ModalService | null>(null)

export default function useModal(): ModalService {
    const context = React.useContext(ModalContext)
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider')
    }
    return context
}

export function ModalProvider({ children }: React.PropsWithChildren<unknown>) {
    const [modalContent, setModalContent] = React.useState<JSX.Element | null>(null)
    const showModal = (content2: React.SetStateAction<JSX.Element | null>) => {
        if (content2 !== modalContent)
            setModalContent(content2)
    }
    const hideModal = () => setModalContent(null)

    return (
        <ModalContext.Provider value={{ showModal, hideModal }}>
            {!!modalContent &&
                <div onClick={hideModal}>
                    {modalContent}
                </div>}
            {children}
        </ModalContext.Provider>
    )
}
