import React from "react"
import { useAsyncValue } from "../hooks/hooks"
import usePwa from "../hooks/usaPwa"
import useWallet from "../hooks/useWallet"
import { IWallet } from "./models"

export type NotificationManager = {
  isSupported: boolean
  isEnabled: boolean
  toggleNotification(): Promise<void>
}

export function useNotificationManager(): NotificationManager {
  const wallet = useWallet()
  const pwaRegistration = usePwa().registration
  const subscriptionState = useAsyncValue(async () => {
    return await pwaRegistration?.pushManager?.getSubscription()
  }, [pwaRegistration])
  const isSupported = /*!isIos &&*/ pwaRegistration !== null
  const isEnabled = isSupported && subscriptionState.hasValue && subscriptionState.value !== null
  const toggleNotification = React.useCallback(async () => {
    if (!isSupported) throw new Error('InvalidOperation')
    if (isEnabled) {
      await disableNotification(wallet, pwaRegistration)
      subscriptionState.setValue(null)
    } else {
      subscriptionState.setValue(await enableNotification(wallet, pwaRegistration))
    }
  }, [isEnabled && isSupported])

  return { isSupported, isEnabled, toggleNotification }
}

async function enableNotification(wallet: IWallet, serviceWorkerRegistration: ServiceWorkerRegistration): Promise<PushSubscription | null> {
  try {
    const oldSubscription = await serviceWorkerRegistration.pushManager.getSubscription()
    if (oldSubscription !== null) {
      await oldSubscription.unsubscribe()
    }

    const serviceInfo = await wallet.getServiceInfo()
    const subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(serviceInfo.publicKey),
    })
    await wallet.subscribeNotification(subscription)
    return subscription
  }
  catch (e) {
    console.error(e)
    return null
  }
}

async function disableNotification(wallet: IWallet, serviceWorkerRegistration: ServiceWorkerRegistration): Promise<void> {
  const oldSubscription = await serviceWorkerRegistration.pushManager.getSubscription()
  if (oldSubscription !== null) {
    await wallet.subscribeNotification({ ...oldSubscription.toJSON(), expirationTime: 0 })
    await oldSubscription.unsubscribe()
  }
}

function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
