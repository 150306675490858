import { HubConnectionBuilder, HttpTransportType, LogLevel, HubConnection, HubConnectionState } from '@microsoft/signalr'
import React from 'react'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { getLnAddress } from '../lib/lnpay'
import { AppWalletState, IWallet, WalletEventReceived, WalletOpened, WalletSettings, Wallet, ServiceInfo, Currency, SendResponse, SendRequest, Invoice, GetExchangeRatesResponse } from '../lib/models'
import strings from '../strings'
import useLocale from './useLocale'
import useLocalStorage from './useLocalStorage'
import useMockWallet from './useMockWallet'

const WALLET_URL = location.host.endsWith('1234') ? 'http://localhost:5000/ws' : '/ws'

const initialWalletState: Readonly<Wallet> = {
  id: '',
  balance: 0,
  events: [],
  settings: {},
}

const initialConnectionState: Readonly<AppWalletState> = {
  opened: localStorage.getItem('token')?.startsWith('"') === true,
  connected: false,
  error: null,
}

type CacheState = {
  receiveUrl: string | null
}

const WalletContext = React.createContext<IWallet | null>(null)

export function WalletProvider({ children }: React.PropsWithChildren<unknown>) {
  const wallet = !!process.env.LNPAY_MOCK_WALLET ? useMockWallet(WALLET_URL) : _useWebSocketWallet(WALLET_URL)
  return (<WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>)
}

export default function useWallet(): IWallet {
  const value = React.useContext(WalletContext)
  if (!value) throw new Error('Wallet is not initialized')
  return value
}

export function useCurrencyCode(): string {
  return useWallet().settings.currencyCode ?? 'SAT'
}

export function useLanguage(): string {
  return useWalletLocale()
}

export function useLnAddress(defaultName?: string): string | undefined {
  const name = useWallet().settings.name
  return getLnAddress(name ?? defaultName)
}

export function useWalletLocale(): string {
  const [locale] = useLocale()
  return React.useContext(WalletContext)?.settings?.language ?? locale ?? 'en'
}

function _useWebSocketWallet(url: string): IWallet {
  const [connectionState, setConnectionState] = useState<AppWalletState>(initialConnectionState)
  const [walletState, setWalletState] = useLocalStorage<Wallet>('wallet', initialWalletState)
  const [token, setToken] = useLocalStorage<string | null>('token', null)
  const [cache, setCache] = useLocalStorage<CacheState | null>('cache', null)

  const onConnected = useCallback(async () => {
    if (token) {
      try {
        await connection.invoke('open', token)
      } catch (e) {
        setConnectionState(s => ({ ...s, connected: true }))
        close()
      }
    } else {
      setConnectionState(s => ({ ...s, connected: true, error: null }))
    }
  }, [])

  const connection = useMemo(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        withCredentials: false,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => {
          return 1000
        }
      })
      .configureLogging(LogLevel.Error)
      .build()

    connection.onreconnecting(error => setConnectionState(s => ({ ...s, connected: false, error: error?.message ?? null })))
    connection.onreconnected(onConnected)
    connection.on('opened', (e: WalletOpened) => {
      setWalletState(e.wallet)
      setToken(e.token)
      setConnectionState(s => ({ ...s, connected: true, opened: true, error: null, ...e.wallet }))
    })

    connection.on('eventReceived', (e: WalletEventReceived) => {
      setWalletState(s => {
        // If logged off, ignore events
        if (s.id === '') { return s }
        return ({ ...s, balance: e.balance, events: [...s.events, e.event] })
      })
    })

    connection.onclose((error?: Error) => setConnectionState(s => ({ ...s, connected: false, error: error?.message ?? null })))

    return connection
  }, [])

  useEffect(() => {
    function start() {
      connection.start()
        .then(onConnected)
        .catch((error: Error) => {
          setConnectionState(s => isSameError(s.error, error.message) ? s : ({ ...s, error: error.message }))
          setTimeout(start, 1000)
        })
    }

    start()

    return () => { connection?.stop() }
  }, [url])

  const close = useCallback(() => {
    setConnectionState(s => ({ ...initialConnectionState, connected: s.connected, opened: false, error: s.error ?? null }))
    setWalletState(initialWalletState)
    setToken(null)
    setCache(null)
    invoke(connection, 'close')
  }, [])

  const getServiceInfo = useCallback(() => invoke<ServiceInfo>(connection, 'getServiceInfo'), [connection])
  const getWithdrawUrl = useCallback(() => invoke<string>(connection, 'getWithdrawUrl', token), [connection, token])
  const getAuthorizeUrl = useCallback(() => invoke<string>(connection, 'getAuthorizeUrl'), [connection])
  const getReceiveUrl = useCallback(async () => {
    if (typeof cache?.receiveUrl === 'string') {
      return cache.receiveUrl
    }
    const receiveUrl = await invoke<string>(connection, 'getReceiveUrl', walletState.id)
    setCache(s => ({ ...s, receiveUrl }))
    return receiveUrl
  }, [connection, walletState?.id])
  const getExchangeRates = useCallback(() => invoke<GetExchangeRatesResponse>(connection, 'getExchangeRates', null), [connection])
  const getCurrencies = useCallback(() => invoke<Currency[]>(connection, 'getCurrencies'), [connection])
  const saveSettings = useCallback(async (settings: WalletSettings) => {
    await invoke<WalletSettings>(connection, 'saveSettings', token, settings)
    setWalletState(s => ({ ...s, settings }))
    return settings
  }, [connection, token])
  const send = useCallback((request: SendRequest) => invoke<SendResponse>(connection, 'send', request, token), [connection, token])
  const decodeInvoice = useCallback((value: string) => invoke<Invoice>(connection, 'decodeInvoice', value), [connection])
  const subscribeNotification = useCallback(async (subscription: PushSubscriptionJSON) => {
    await invoke(connection, 'subscribeNotification', token, subscription)
  }, [connection, token])
  const validateDestination = useCallback((destination: string) => invoke<string | null>(connection, 'validateDestination', destination), [connection])

  const wallet = useMemo(() => {
    return {
      ...connectionState,
      ...walletState,
      getServiceInfo,
      getAuthorizeUrl,
      getReceiveUrl,
      getWithdrawUrl,
      getExchangeRates,
      getCurrencies,
      send,
      close,
      saveSettings,
      subscribeNotification,
      decodeInvoice,
      validateDestination,
    }
  }, [connection, connectionState, walletState])

  return wallet
}

function isSameError(e1: string | null, e2: string | null) {
  if (typeof e1 === 'string' && typeof e2 === 'string') {
    return e1.trim().localeCompare(e2.trim()) === 0
  }
  return false
}

// TODO: figure out a more elegant way to handle errors
function normalizeError(e: Error) {
  const keyword = 'Exception:'
  const index = e.message.indexOf(keyword)
  if (index >= 0) {
    const errorCode = e.message.substr(index + keyword.length).trim()
    return new Error((strings.errors as Record<string, string>)[errorCode] ?? errorCode)
  } else {
    return e
  }
}

async function invoke<T>(connection: HubConnection | null, methodName: string, ...args: unknown[]): Promise<T> {
  if (connection?.state !== HubConnectionState.Connected) {
    throw new Error('Not connected')
  }

  try {
    return await connection.invoke<T>(methodName, ...args)
  } catch (e) {
    console.error(methodName, e)
    throw normalizeError(e)
  }
}
