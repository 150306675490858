import React, { useCallback } from 'react'
import QRCode from 'react-qr-code'
import useToast from '../hooks/useToast'
import { Container, Button, Col, Row } from '../lib/ui'
import strings from '../strings'
import { ArrowUpRightIcon, ClipboardIcon, ShareIcon, ExclamationTriangleIcon, EyeIcon, EyeSlashIcon, DownloadIcon } from './icons'

const defaultButtons = {
  open: true,
  copy: true,
  share: false,
  download: false
}

export default function LnUrl(props: {
  title?: string
  url: string
  extra?: React.ReactNode
  privacyWarning?: string
  buttons?: { open?: boolean, copy?: boolean, share?: boolean, download?: boolean }
}) {
  const [show, setShow] = React.useState(props.privacyWarning == null)
  const toast = useToast()
  const qrCodeSize = window.screen.height / 4
  const buttons = props.buttons ?? defaultButtons

  const copy = useCallback(() => {
    navigator.clipboard.writeText(props.url)
      .then(() => toast.addToast(strings._.copied))
      .catch(e => alert('could not copy: ' + e))
  }, [props.url])

  const share = useCallback(() => {
    shareOrDownloadQrCode(qrCodeSize, true)
  }, [props.url])

  const download = useCallback(() => {
    shareOrDownloadQrCode(qrCodeSize, false)
  }, [props.url])

  return (
    <Container className="d-flex h-100 align-items-center justify-content-center">
      <Row>
        {
          !!props.privacyWarning && !show &&
          <Col className="text-center justify-content-center">
            <ExclamationTriangleIcon height={100} width={100} />
            <p className="fw-bold text-center my-5">{props.privacyWarning}</p>
            <Button variant="danger" onClick={() => setShow(!show)}>
              {strings.withdraw.revealQrCode}
              <span className="ms-2">
                <EyeIcon height={20} width={20} />
              </span>
            </Button>
          </Col>
        }

        {show && <Col className="justify-content-center text-center">
          {props.title && <h3>{props.title}</h3>}

          {props.extra && props.extra}

          <div className="bg-white mb-1 mb-md-4 p-3 d-inline-block rounded-3" onClick={copy}>
            <QRCode id="qrCode" value={props.url} size={qrCodeSize} onClick={copy} />
          </div>

          <div className="mt-2 d-flex justify-content-evenly align-items-center">
            {buttons.copy && <ClipboardIcon onClick={copy} className="align-baseline my-3 mx-1" />}
            {buttons.share && <Button id="share" onClick={share} ><ShareIcon className="align-baseline" /> {strings.receive.shareButton}</Button>/**TODO: language */}
            {buttons.open && <Button id="open" href={'lightning:' + props.url}><ArrowUpRightIcon className="align-baseline" /> {strings._.open}</Button>}
            {props.privacyWarning && <EyeSlashIcon onClick={() => setShow(!show)} height={20} width={20} className="align-baseline my-3 mx-1" />}
            {buttons.download && <DownloadIcon onClick={download} className="align-baseline my-3 mx-1" />}
          </div>
        </Col>}
      </Row>
    </Container >
  )
}

function shareOrDownloadQrCode(qrCodeSize: number, share: boolean) {
  const qrCode = document.getElementById('qrCode')
  if (qrCode === null) { throw new Error('qrCode not found') }

  // get svg data and make it base64
  const xml = new XMLSerializer().serializeToString(qrCode)
  const svg64 = btoa(xml) //TODO: find replacement of this deprecated function

  // set it as the source of the img
  const tempImage = new Image(qrCodeSize, qrCodeSize)
  tempImage.setAttribute('src', 'data:image/svg+xml;base64,' + svg64)
  tempImage.onload = () => {
    // draw image onto canvas after it is loaded
    const tempCanvas = document.createElement('canvas')
    tempCanvas.width = qrCodeSize
    tempCanvas.height = qrCodeSize

    const ctx = tempCanvas.getContext('2d')
    ctx?.drawImage(tempImage, 0, 0)

    if (share)
      shareCanvasAsImage(tempCanvas)
    else
      downloadCanvasAsImage(tempCanvas)
  }
}

function shareCanvasAsImage(canvas: HTMLCanvasElement) {
  canvas.toBlob(async (blob) => {
    if (!blob)
      return
    const files = [new File([blob], 'LNPay_' + new Date().getTime() + '.png', { type: blob.type })]
    const shareData = {
      text: strings.receive.shareText,
      title: strings.receive.shareTitle,
      files,
    }
    try {
      await navigator.share(shareData)
    }
    catch (err) {
      if (err.name !== 'AbortError')
        throw err
    }
  })
}

function downloadCanvasAsImage(canvas: HTMLCanvasElement) {
  const canvasContent = canvas.toDataURL('image/png', 1)
  const downloadLink = document.createElement('a')
  downloadLink.href = canvasContent.replace(/^data:image\/[^;]*/, 'data:application/octet-stream')
  downloadLink.download = 'LNPay_' + new Date().getTime() + '.png' /* unique name so it doesn't prompt about downloading again */
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}