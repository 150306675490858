import { TransferEvent } from './models'
import { getTransferComment, tryGetTransferPeerName } from './wallet'

export const version = process.env.LNPAY_VERSION ?? 'DEV'
const NameRegex = /^(\w+([-+.]\w+)*)$/

export function getLnAddress(name: string | undefined) {
  if (!name) {
    return undefined
  }

  return (name + '@' + location.hostname).toLocaleLowerCase()
}

export function isValidName(name: string | undefined): boolean {
  if (name == null || name === '')
    return true
  return NameRegex.test(name)
}

export function getEventKey(e: TransferEvent): string {
  return e.transactionId + e.type
}

export async function showTransferNotification(registration: ServiceWorkerRegistration, event: TransferEvent): Promise<void> {
  const amount = (event.amount / 1000) + ' sat'
  const peer = tryGetTransferPeerName(event)
  const comment = getTransferComment(event)
  const body = [peer, comment, amount].filter(x => x != null).join(' ')

  await registration.showNotification('LN Pay', {
    body,
  })
}
