﻿import { useState } from 'react'
import { AppWalletState, Currency, ExchangeRateMap, Invoice, IWallet, SendRequest, SendResponse, ServiceInfo, TransferEvent, Wallet, WalletSettings } from '../lib/models'
import { useTimeout } from './hooks'
import useLocalStorage from './useLocalStorage'
import { isValidName } from '../lib/lnpay'

const defaultState: Wallet = {
    balance: 100_000_000,
    events: [],
    id: '0',
    settings: { name: 'test-name', currencyCode: 'USD' },
}

const initialAppWalletState: AppWalletState = {
    connected: false,
    error: null,
    opened: false,
}

type MockContact = { readonly id: string, readonly name: string }

const CONTACTS: ReadonlyArray<MockContact> = [
    { id: '1', name: 'Alice' },
    { id: '2', name: 'Bob' },
    { id: '3', name: 'Charlie' },
    { id: '4', name: 'Dennis' },
]

const CURRENCIES: ReadonlyArray<Currency> = [
    { 'code': 'SAT', 'name': 'Satoshi', 'symbol': 'sat', 'decimals': 0 },
    { 'code': 'BTC', 'name': 'Bitcoin', 'symbol': '₿', 'decimals': 8 },
    { 'code': 'CNY', 'name': 'Chinese Yuan', 'symbol': '¥', 'decimals': 2 },
    { 'code': 'EUR', 'name': 'Euro', 'symbol': '€', 'decimals': 2 },
    { 'code': 'GBP', 'name': 'British Pound Sterling', 'symbol': '£', 'decimals': 2 },
    { 'code': 'IDR', 'name': 'Indonesian Rupiah', 'symbol': 'Rp', 'decimals': -2 },
    { 'code': 'JPY', 'name': 'Japanese Yen', 'symbol': '¥', 'decimals': 0 },
    { 'code': 'RUB', 'name': 'Russian Ruble', 'symbol': 'р.', 'decimals': 2 },
    { 'code': 'USD', 'name': 'United States Dollar', 'symbol': '$', 'decimals': 2 }
]

const EXCHANGE_RATES: ExchangeRateMap = {
    'SAT': { provider: '', time: '', value: 1 },
    'BTC': { provider: '', time: '', value: 100000000 },
    'CNY': { provider: '', time: '', value: 252.33727400042898 },
    'EUR': { provider: '', time: '', value: 1868.7397219315294 },
    'GBP': { provider: '', time: '', value: 2212.046806910434 },
    'IDR': { provider: '', time: '', value: 0.11360223120053221 },
    'JPY': { provider: '', time: '', value: 14.177037169356051 },
    'RUB': { provider: '', time: '', value: 22.78541696857344 },
    'USD': { provider: '', time: '', value: 1616.3967284130217 },
}

export default function useMockWallet(url: string): IWallet {
    const [state, setState] = useLocalStorage<Wallet>('mock.wallet', defaultState)
    const [appState, setAppState] = useState<AppWalletState>(initialAppWalletState)
    const connectedDelay = parseInt(new URLSearchParams(location.search).get('connectedDelay') ?? '2000')
    const error = new URLSearchParams(location.search).get('error')
    const opened = new URLSearchParams(location.search).get('opened') !== 'false'
    useTimeout(() => {
        if (state.events.length === 0) {
            setState({ ...state, events: generateWalletEvents() })
        }
        setAppState(s => ({ ...s, connected: error == null, opened, error: error }))
    }, connectedDelay)

    return {
        ...state,
        ...appState,
        validateDestination: (destination: string) => {
            return Promise.resolve(isValidName(destination) ? null : 'ERROR')
        },
        close: () => { setAppState(s => ({ ...s, opened: false })) },
        getCurrencies: async () => {
            await delay(1000)
            return CURRENCIES
        },
        getExchangeRates: async () => {
            await delay(1000)
            return { time: new Date().toISOString(), rates: EXCHANGE_RATES }
        },
        getServiceInfo: () => Promise.resolve<ServiceInfo>({ publicKey: 'dummy' }),
        subscribeNotification: () => Promise.resolve(),
        getAuthorizeUrl: () => Promise.resolve(url),
        getReceiveUrl: () => Promise.resolve(url),
        getWithdrawUrl: () => Promise.resolve(url),
        saveSettings: (value: WalletSettings) => {
            setState(s => ({ ...s, settings: value }))
            return Promise.resolve(value)
        },
        decodeInvoice: async (value: string) => {
            console.debug('decodeInvoice', value)
            await delay(1000)
            return {} as Invoice
        },
        send: async (request: SendRequest) => {
            console.debug('send', request)
            try {
                const t = parseInt(request.comment ?? '1000')
                await delay(t)
            } catch {
                await delay(1000)
            }
            if (request.comment === 'error') {
                throw new Error('Failed to send')
            } else {
                return { status: 'SUCCESS' } as SendResponse
            }
        }
    }
}

function generateWalletEvents(): TransferEvent[] {
    const createEvent = getEventFactory()
    return [
        createEvent({ age: 0 }),
        createEvent({ age: 0, comment: 'Good morning 🌅' }),
        createEvent({ age: 1, comment: 'Good evening 🌅' }),
        createEvent({ age: 2, comment: 'Good evening 🌅' }),
        createEvent({ age: 3, comment: 'Good evening 🌅' }),
        createEvent({ age: 5, comment: 'Good evening 🌅' }),
        createEvent({ age: 6, comment: 'Good evening 🌅' }),
        createEvent({ age: 7, comment: 'Good evening 🌅' }),
        createEvent({ age: 35, comment: 'Good evening 🌅' }),
        createEvent({ age: 75, comment: 'Good evening 🌅' }),
        createEvent({ age: 100, comment: 'Good evening 🌅' }),
        createEvent({
            age: 60,
            comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum'
        }),
    ].sort((a, b) => a.time.localeCompare(b.time))
}

function makeDate(age: number): string {
    const date = new Date()
    date.setDate(date.getDate() - age)
    return date.toISOString()
}

function getEventFactory() {
    let txId = 1
    return (props: {
        age: number
        status?: 'requested' | 'succeeded' | 'failed'
        comment?: string,
        amount?: number
    }) => {
        const contact = randomItem(CONTACTS)
        return ({
            transactionId: (++txId).toString(),
            walletId: '1',
            type: 'TransferEvent',
            status: props.status ?? 'succeeded',
            amount: props.amount ?? Math.random() * 1000 * 1000 * 1000 * (randomBoolean() ? 1 : -1),
            time: makeDate(props.age),
            comment: props.comment,
            uri: `wallet://${contact.id}?name=${contact.name}`,
        } as TransferEvent)
    }
}

function randomBoolean(): boolean {
    return Math.random() > 0.5
}

function randomItem<T>(items: ReadonlyArray<T>): T {
    return items[Math.round(Math.random() * (items.length - 1))]
}

function delay(valueMs: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, valueMs))
}