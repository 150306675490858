import React from 'react'
import strings from '../strings'
import useLocalStorage from './useLocalStorage'

export type LocaleState = [string, (locale: string) => void]

const LocaleContext = React.createContext<LocaleState | null>(null)

export default function useLocale(): LocaleState {
    const context = React.useContext(LocaleContext)
    if (context === null) { throw new Error('useLocale must be used within a LocaleProvider') }
    return context
}

export function LocaleProvider({ children }: React.PropsWithChildren<unknown>) {
    const [locale, setLocale] = useLocalStorage('locale', 'en')
    const setLocaleOverride = (locale: string) => {
        console.debug('setLocale', locale)
        strings.setLanguage(locale)
        setLocale(locale)
    }

    const state: LocaleState = [locale, setLocaleOverride]

    return (
        <LocaleContext.Provider value={state}>
            {children}
        </LocaleContext.Provider>
    )
}
