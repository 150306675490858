{
  "_": {
    "amount": "Quantità",
    "home": "Casa",
    "balance": "Bilancia",
    "cancel": "Annulla",
    "connecting": "Collegamento...",
    "copy": "copia",
    "copied": "Copiato",
    "comment": "Commento",
    "commentPlaceholder": "Commento (opzionale)",
    "confirmation": "Conferma",
    "failed": "Non riuscito",
    "activity": "Attività",
    "next": "Prossimo",
    "back": "Di ritorno",
    "name": "Nome",
    "noService": "Servizio non disponibile",
    "ok": "OK",
    "open": "Aprire",
    "offline": "Disconnessa",
    "receive": "Ricevere",
    "received": "Ricevuto",
    "required": "Necessaria",
    "save": "Salva",
    "saved": "Salvato",
    "send": "Spedire",
    "sent": "Inviata",
    "sentTo": "Inviato a",
    "settings": "Impostazioni",
    "success": "Successo",
    "withdraw": "Ritirare",
    "withdrawn": "Ritirata"
  },
  "welcome": {
    "getStarted": "Iniziare",
    "step1Title": "Effettuare il pagamento fantastico con ln pay",
    "step1Content": "Utilizza il tuo portafoglio Phoenix o Breez Lightning per accedere, viene pagato e prelevare fondi.",
    "step2Title": "Accesso anonimo con lnurl attraverso le app del fulmine",
    "step2Content": "Nessuna email o informazioni personali necessarie.",
    "step3Title": "Condividi il tuo codice QR con il mondo",
    "step3Content": "Stampa il codice QR su carta da visualizzare sul tuo posto, inviare e-mail o posizionare un'immagine sul tuo sito web o app sui social media - facile come quello!",
    "step4Content": "Raccogli pagamenti e ritirati facilmente al tuo portafoglio",
    "step4Title": "Qualsiasi portafoglio a fulmine che supporta LnPay funziona, come Phoenix o Breez Portafoglio.",
    "letsGo": "Let's go!"
  },
  "login": {
    "login": "login",
    "text1": "Apri la tua app Lightning Wallet (Phoenix, Breez, ecc ...)",
    "text2": "Scansione o copia QR per accedere in modo anonimo",
    "text3": "Qualsiasi portafoglio a fulmine che supporta Lnurl-Autener funzionerà."
  },
  "send": {
    "destination": "Destinazione",
    "destinationPlaceholder": "Nome utente LNPAY",
    "remember": "Ricorda \"a\" e \"importo\" per il futuro"
  },
  "receive": {
    "text1": "Condividi questo codice QR o il tuo indirizzo Lightning con i tuoi colleghi",
    "text2": "Loannano e inviano i pagamenti dalle loro app di fulmini",
    "text3": "Stampa codice QR su carta da visualizzare nel tuo negozio, inviare e-mail o posizionare un'immagine sul tuo sito web o sui social media - facile!",
    "setAddress": "Lightning Address",
    "shareButton": "Condividere",
    "shareTitle": "LNPay QR Code",
    "shareText": "Ecco il mio codice QR lnPay"
  },
  "withdraw": {
    "availableAmount": "Importo disponibile",
    "revealQrCode": "Rivelare il codice QR.",
    "text1": "Scansiona questo codice QR con la tua app Lightning Wallet",
    "text2": "Ritirare i tuoi fondi.BOOM!",
    "text3": "Assicurati di essere in un ambiente privato prima di rivelare il codice QR.Chiunque può ritirare i tuoi fondi con questo codice QR."
  },
  "settings": {
    "minimumAmountInvalid": "Deve essere compreso tra 1 e massimo.",
    "maximumAmountInvalid": "Deve essere tra minimo e 1.000.000.",
    "minimumAmountLabel": "Quantità minima (SAT)",
    "maximumAmountLabel": "Quantità massima (SAT)",
    "receivePaymentsAt": "Ricevere pagamenti a.",
    "defaultComment": "Commento predefinito",
    "commentAllowed": "Abilita commenti",
    "language": "Lingua",
    "currency": "Valuta",
    "enableNotifications": "Notifica non validas",
    "disableNotifications": "Disabilita le notifiche"
  },
  "dt": {
    "today": "Oggi",
    "yesterday": "Ieri",
    "thisWeek": "Questa settimana",
    "thisMonth": "Questo meseh",
    "thisYear": "Quest'anno",
    "day": "Giorno",
    "week": "Settimana",
    "month": "Mese",
    "year": "Anno",
    "since": "Da quando"
  },
  "errors": {
    "TransferAmountTooSmall": "Importo troppo piccolo",
    "NameInvalid": "Nome non valido",
    "NameAlreadyExists": "Il nome esiste già"
  }
}