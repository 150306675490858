import React from 'react'

const RECENT_LIST_MAX_SIZE = 10

export default function RecentList<T>(props: {
  value: T;
  onChange: (value: T) => void;
  values: ReadonlyArray<T>;
}) {
  return (
    <div>{props.values.map(v => <span
      className={`badge small rounded-pill ${v === props.value ? 'bg-dark' : ''}`}
      key={`${v}`}
      onClick={() => {
        props.onChange(v)
      }}
    >{v}</span>
    )}</div>
  )
}

export function updateRecentList<T>(items: ReadonlyArray<T>, value: Readonly<T>, maxSize = RECENT_LIST_MAX_SIZE): ReadonlyArray<T> {
  if (items.length >= maxSize) {
    items = items.slice(0, maxSize - 1)
  }
  return [...items.filter(v => v !== value), value]
}
