import React from 'react'
import { Container } from '../lib/ui'

export default function Notice(props: { icon?: JSX.Element, message?: string, detail?: string }) {
    return (
        <div className=" text-center position-absolute top-50 start-50 translate-middle">
            {/* <div className="flex-grow-1 bg-dark d-flex align-items-center text-center"> */}
            <Container >
                <div className="mb-2">
                    {props.icon}
                </div>
                <p>
                    {props.message}
                </p>
                <p>
                    {props.detail}
                </p>
            </Container>
        </div>
    )
}