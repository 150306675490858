import { useEffect, useRef } from 'react'
import { Col, Container, Row } from '../lib/ui'
import { TransferEvent, WalletEvent } from '../lib/models'
import { useCurrencyConverter } from './useExchange'
import useToast from './useToast'
import useWallet from './useWallet'
import React from 'react'
import strings from '../strings'
import { getTransferComment, isCompletedTransfer } from '../lib/wallet'

export default function useEventMonitor() {
    const wallet = useWallet()
    const toast = useToast()
    const events = useRef<WalletEvent[] | null>(null)

    useEffect(() => {
        if (!wallet.opened) {
            events.current = null
            return
        }

        if (events.current === null) {
            events.current = wallet.events
        }

        const newEvents = wallet.events.slice(events.current.length)
        const newTransferEvents = newEvents.filter(isCompletedTransfer)

        if (newTransferEvents.length > 1) {
            toast.addToast(<ManyTransferToast count={newEvents.length} />)
        }
        else if (newTransferEvents.length === 1) {
            toast.addToast(<TransferToast event={newTransferEvents[0]} />)
        }

        events.current = wallet.events
    }, [wallet.events])
}

function TransferToast({ event }: { event: TransferEvent; }) {
    const comment = getTransferComment(event)
    const amount = useCurrencyConverter().format(event.amount)

    return (
        <Container>
            <Row>
                <Col className="text-center text-success">
                    <h6>
                        {`${comment} ${amount}`}
                    </h6>
                </Col>
            </Row>
        </Container>
    )
}

function ManyTransferToast({ count }: { count: number; }) {
    return (
        <Container>
            <Row>
                <Col className="text-center">
                    <h6>
                        {`${strings._.activity} (${count.toLocaleString()})`}
                    </h6>
                </Col>
            </Row>
        </Container>
    )
}