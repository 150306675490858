{
  "_": {
    "amount": "Jumlah",
    "home": "Rumah",
    "balance": "Seimbang",
    "cancel": "Batal",
    "connecting": "Menghubungkan...",
    "copy": "Salin",
    "copied": "Disalin",
    "comment": "Komentar",
    "commentPlaceholder": "Komentar (opsional)",
    "confirmation": "Konfirmasi",
    "failed": "Gagal",
    "activity": "Aktivitas",
    "next": "Lanjut",
    "back": "Kembali",
    "name": "Nama",
    "noService": "Layanan tidak tersedia",
    "ok": "Oke",
    "open": "Membuka",
    "offline": "Jaringan Terputus",
    "receive": "Terima",
    "received": "Diterima",
    "required": "Yang Dibutuhkan",
    "save": "Simpan",
    "saved": "Disimpan",
    "send": "Kirim",
    "sent": "Terkirim",
    "sentTo": "Dikirim ke",
    "settings": "Pengaturan",
    "success": "Sukses",
    "withdraw": "Menarik",
    "withdrawn": "Ditarik"
  },
  "welcome": {
    "getStarted": "Dimulai",
    "step1Title": "Membuat pembayaran Luar Biasa Dengan LN",
    "step1Content": "Gunakan dompet Phoenix atau Breez Lightning Anda untuk masuk, dibayar dan menarik dana",
    "step2Title": "Login anonim dengan LNURL melalui aplikasi Lightning Wallet",
    "step2Content": "Tidak perlu email dan informasi pribadi",
    "step3Title": "Bagikan kode QR Anda dengan dunia",
    "step3Content": "Cetak kode QR di atas kertas untuk ditampilkan di tempat Anda, kirim email atau letakkan gambar di situs web Anda atau aplikasi media sosial - semudah itu!",
    "step4Content": "Kumpulkan pembayaran dan tarik dengan mudah ke Lighthing Wallet anda",
    "step4Title": "Setiap Lighting Wallet mendukung LNPay, seperti dompet Phoenix atau Breez.",
    "letsGo": "Let's go!"
  },
  "login": {
    "login": "Masuk/Gabung",
    "text1": "Buka aplikasi Lighting Wallet Anda (Phoenix, Breez, dll ...)",
    "text2": "Pindai atau salin QR untuk login secara anonim",
    "text3": "Setiap Lighting Wallet yang mendukung LNURL dapat bekerja"
  },
  "send": {
    "destination": "Tujuan",
    "destinationPlaceholder": "Nama pengguna Lnpay",
    "remember": "Ingat \"untuk\" dan \"jumlah\" untuk masa depan"
  },
  "receive": {
    "text1": "Bagikan kode QR atau alamat Lightning Anda dengan rekan-rekan Anda",
    "text2": "Mereka memindai dan mengirim pembayaran ke anda dari aplikasi Lighting Wallet mereka",
    "text3": "Cetak kode QR di atas kertas untuk ditampilkan di toko Anda, kirim melalui email atau letakkan gambar di situs web atau media sosial Anda - Mudah!",
    "setAddress": "Lightning Address",
    "shareButton": "Membagikan",
    "shareTitle": "LNPay QR Code",
    "shareText": "Ini kode QR lnpay saya"
  },
  "withdraw": {
    "availableAmount": "Jumlah yang tersedia",
    "revealQrCode": "Mengungkapkan QR Code.",
    "text1": "Pindai kode QR ini dengan aplikasi Lighting Wallet Anda",
    "text2": "Tarik dana Anda, BOOM!",
    "text3": "Pastikan untuk berada di tempat aman sebelum ungkapkan kode QR. Siapa pun dapat menarik dana Anda dengan kode QR ini."
  },
  "settings": {
    "minimumAmountInvalid": "Harus antara 1 dan maksimum.",
    "maximumAmountInvalid": "Harus antara minimum dan 1.000.000.",
    "minimumAmountLabel": "Jumlah minimum (SAT)",
    "maximumAmountLabel": "Jumlah maksimum (SAT)",
    "receivePaymentsAt": "Menerima pembayaran",
    "defaultComment": "Komentar default",
    "commentAllowed": "Aktifkan komentar",
    "language": "Bahasa",
    "currency": "Mata uang",
    "enableNotifications": "Aktifkan notifikasi",
    "disableNotifications": "Nonaktifkan notifikasi"
  },
  "dt": {
    "today": "Hari ini",
    "yesterday": "Kemarin",
    "thisWeek": "Minggu ini",
    "thisMonth": "Bulan ini",
    "thisYear": "Tahun ini",
    "day": "Hari",
    "week": "Minggu",
    "month": "Bulan",
    "year": "Tahun",
    "since": "Sejak"
  },
  "errors": {
    "TransferAmountTooSmall": "Jumlah terlalu kecil",
    "NameInvalid": "Salah nama",
    "NameAlreadyExists": "Nama sudah ada"
  }
}