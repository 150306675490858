import React from 'react'
import useWallet from './useWallet'
import strings from '../strings'
import Notice from '../components/Notice'
import { WifiOffIcon } from '../components/icons'
import { Spinner } from '../lib/ui'

export default function useNotice() {
  const wallet = useWallet()

  // Connection Pending (initial state)
  if (!wallet.connected && !wallet.error) {
    return (
      <Notice
        icon={<>
          <Spinner className="m-1" animation="grow" variant="primary" size="sm" />
          <Spinner className="m-1" animation="grow" variant="primary" size="sm" />
          <Spinner className="m-1" animation="grow" variant="primary" size="sm" />
        </>}
        message={strings._.connecting}
      />
    )
  }

  // Connection Failed, retrying 
  if (!wallet.connected && wallet.error) {
    return (
      <Notice
        icon={<WifiOffIcon color="orange" />}
        message={`${strings._.offline}`}
        detail={wallet.error ?? ''}
      />
    )
  }

  // All good
  return null
}