import React from 'react'
import strings from '../strings'
import useLocalStorage from '../hooks/useLocalStorage'
import CurrencyAmountText from '../components/CurrencyAmountText'
import { Button, Container, Form, Spinner } from '../lib/ui'
import { SendRequest } from '../lib/models'
import RecentList, { updateRecentList } from '../components/RecentList'
import useToast from '../hooks/useToast'
import CurrencyInput from '../components/CurrencyInput'
import { useCurrencies, useCurrencyConverter2 } from '../hooks/useExchange'
import { CurrencyAmount, serializeCurrencyAmount } from '../lib/currency'
import useWallet, { useCurrencyCode } from '../hooks/useWallet'
import useAsyncAction, { ActionStatus } from '../hooks/useAsyncAction'
import useDebounce from '../hooks/useDebounce'

type Recents = {
  readonly destinations: ReadonlyArray<string>
  readonly amounts: ReadonlyArray<string>
  readonly comments: ReadonlyArray<string>
}

const initialRecents: Recents = Object.freeze({
  destinations: [],
  amounts: [],
  comments: [],
})

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const initialSendRequest: SendRequest = { destination: '', amount: undefined! }
type SendProps = { amount: CurrencyAmount, request: SendRequest }

export default function SendPage() {
  const toast = useToast()
  const wallet = useWallet()
  const currencies = useCurrencies()
  const [request, setRequest] = useLocalStorage<SendRequest>('sendPage.request', initialSendRequest)
  const [amount, setAmount] = useLocalStorage<CurrencyAmount>('sendPage.amount', { currencyCode: wallet.settings.currencyCode, amount: null })
  const [recents, setRecents] = useLocalStorage('sendPage.recents', initialRecents)
  const [error, setError] = React.useState<string | null>(null)
  const [remember, setRemember] = React.useState(true)
  const currencyConverter = useCurrencyConverter2()
  const isInvoice = isInvoiceLike(request.destination)
  const destinationError = useDestinationValidation(request.destination)

  const sendCore = React.useMemo(() => async (props1: SendProps) => {
    toast.disableToasts(1000)
    const response = await wallet.send(props1.request)
    if (!isInvoice) {
      setRecents(s => ({
        destinations: updateRecentList(s.destinations, request.destination),
        amounts: updateRecentList(s.amounts, serializeCurrencyAmount(amount)),
        comments: remember ? updateRecentList(s.comments, request.comment ?? '') : s.comments,
      }))
    }
    setRequest({ ...initialSendRequest })
    setAmount({ currencyCode: wallet.settings.currencyCode, amount: null })
    return response
  }, [request])

  const sendAction = useAsyncAction<SendProps, unknown>({
    action: sendCore,
    confirm: i => <ConfirmMessage request={i.request} amount={i.amount} />,
    success: (i) => <SuccessMessage request={i.request} amount={i.amount} />,
    error: (_, e) => <ErrorMessage error={e} />,
  })

  const isDisabled = !(request.destination?.length > 0 && request.amount > 0 && sendAction.status === ActionStatus.Created) || destinationError != null
  const onClickSend = () => sendAction.start({ request, amount })

  return (
    <Container>
      {sendAction.dialog}
      <div className="text-center">
        <small className="fw-bold">{strings.withdraw.availableAmount}</small>
        <h2 className="text-primary mb-3 mb-md-4"><CurrencyAmountText amount={wallet.balance} className="text-primary" /></h2>
      </div>

      <PaymentDestinationInput
        value={request.destination}
        error={error ?? destinationError}
        onChange={async destination => {
          setError(null)
          setRequest(r => ({ ...r, destination }))
          if (isInvoiceLike(destination)) {
            try {
              const invoice = await wallet.decodeInvoice(destination)
              setRequest(r => ({ ...r, destination, amount: invoice.amount, comment: invoice.comment }))
              setAmount({ amount: invoice.amount / 1000, currencyCode: 'SAT' })
            } catch (e) {
              setError('InvoiceInvalid')
            }
          } else {
            setRequest(r => ({ ...r, destination }))
          }
        }}
        mru={recents.destinations} />

      <CurrencyInput
        disabled={isInvoice}
        value={amount}
        onChange={value => {
          setAmount(value)
          setRequest(r => ({ ...r, amount: currencyConverter.fromCurrency(value.amount ?? 0, value.currencyCode) }))
        }}
        mru={recents.amounts}
        id="amount"
        label={strings._.amount}
        currencies={currencies}
        maximumValue={wallet.balance.toString()}
      />

      <PaymentCommentInput
        disabled={isInvoice}
        value={request.comment ?? ''}
        onChange={comment => setRequest(r => ({ ...r, comment }))} />

      <Form.Group className="mb-3 input-group-sm d-flex justify-content-between">
        <Form.Label>{strings.send.remember}</Form.Label>
        <Form.Check type="switch"
          disabled={isInvoice}
          defaultChecked={remember && !isInvoice}
          onChange={e => setRemember(e.target.checked)} />
      </Form.Group>

      <Container className="text-center">
        <Button disabled={isDisabled} onClick={onClickSend} className="w-50">
          {sendAction.status === ActionStatus.Pending && <Spinner variant="secondary" size="sm" className="me-2" animation="border" />}
          {strings._.send}
        </Button>
      </Container>
    </Container>
  )
}

function isInvoiceLike(destination: string) {
  return destination.startsWith('ln') && destination.length > 120 && destination.indexOf('@') < 0
}

function PaymentDestinationInput({ value, onChange, mru, error }: {
  value: string
  onChange: (value: string) => void
  mru: ReadonlyArray<string>
  error: string | null | undefined
}) {

  return (
    <Form.Group className="mb-3">
      <Form.Label>
        {strings.send.destination}
      </Form.Label>
      <Form.Control
        id="name"
        type="text"
        spellCheck={false}
        placeholder={strings.send.destinationPlaceholder}
        value={value}
        isValid={error === null}
        isInvalid={error != null}
        onChange={e => onChange(e.target.value)} />
      {!!mru && <RecentList values={mru} value={value} onChange={onChange} />}
      <Form.Control.Feedback type="valid" />
      {error !== null && <Form.Control.Feedback type="invalid">{`${error}`}</Form.Control.Feedback>}
    </Form.Group>
  )
}

function PaymentCommentInput({ disabled, value, onChange }: {
  disabled?: boolean
  value: string
  onChange: (value: string) => void
}) {
  return (
    <Form.Group className="my-4">
      <Form.Control
        id="comment"
        disabled={disabled}
        type="text"
        as="textarea"
        placeholder={strings._.commentPlaceholder}
        value={value}
        onChange={e => onChange(e.target.value)} />
    </Form.Group>
  )
}

function ConfirmMessage(props: SendProps) {
  const currencyCode = useCurrencyCode()
  const showDefaultCurrencyAmount = currencyCode !== props.amount.currencyCode
  const showSatoshiAmount = props.amount.currencyCode !== 'SAT'

  return <div className="p-3 fs-4">
    {isInvoiceLike(props.request.destination) && ` ${strings._.send} `}
    {!isInvoiceLike(props.request.destination) && ` ${strings._.send} ${props.request.destination} `}
    <CurrencyAmountText amount={props.request.amount} currencyCode={props.amount.currencyCode} />
    <br />
    {showDefaultCurrencyAmount && <CurrencyAmountText className='fs-6 text-muted' amount={props.request.amount} currencyCode={currencyCode} />}
    {showDefaultCurrencyAmount && showSatoshiAmount && <span className='fs-6 text-muted'> / </span>}
    {showSatoshiAmount && <CurrencyAmountText className='fs-6 text-muted' amount={props.request.amount} currencyCode="SAT" />}
  </div>
}

function SuccessMessage(props: SendProps) {
  const currencyCode = useCurrencyCode()
  const showDefaultCurrencyAmount = currencyCode !== props.amount.currencyCode
  const showSatoshiAmount = props.amount.currencyCode !== 'SAT'

  return <div className="p-3 fs-4">
    <CurrencyAmountText amount={props.request.amount} currencyCode={props.amount.currencyCode} />
    {isInvoiceLike(props.request.destination) && ` ${strings._.sent.toLocaleLowerCase()} `}
    {!isInvoiceLike(props.request.destination) && ` ${strings._.sentTo.toLocaleLowerCase()} ${props.request.destination} `}
    <br />
    {showDefaultCurrencyAmount && <CurrencyAmountText className='fs-6 text-muted' amount={props.request.amount} currencyCode={currencyCode} />}
    {showDefaultCurrencyAmount && showSatoshiAmount && <span className='fs-6 text-muted'> / </span>}
    {showSatoshiAmount && <CurrencyAmountText className='fs-6 text-muted' amount={props.request.amount} currencyCode="SAT" />}
  </div>
}

function ErrorMessage(props: { error: Error }) {
  const message = props.error.message

  return <h6 className="p-3">
    {message}
  </h6>
}

/**
 * Hook for destination validation.
 * @param destination The destination to validate.
 * @returns error message or undefined if no error.
 */
function useDestinationValidation(destination: string): string | null | undefined {
  const wallet = useWallet()
  const [error, setError] = React.useState<string | null | undefined>()
  const debouncedDestination = useDebounce(destination, 500)

  React.useEffect(() => {
    // Only check if there is a destination
    if (debouncedDestination == '') {
      setError(undefined)
    }
    else {
      wallet.validateDestination(debouncedDestination).then(setError).catch(setError)
    }
    return () => { }
  }, [debouncedDestination])
  return error
}

