import React from 'react'
import { Form } from '../lib/ui'
import RecentList from './RecentList'

export default function NumberInput(props: {
    id: string
    disabled?: boolean
    label: string
    value: number | undefined
    onChange: (value: number | undefined) => void
    onValidate: (value: number) => string | null
    mru?: ReadonlyArray<number>
}) {
    const error = props.value !== undefined ? props.onValidate(props.value) : null
    return (
        <Form.Group className="mb-3">
            <Form.Label>{props.label}</Form.Label>
            <Form.Control
                id={props.id}
                disabled={props.disabled}
                type="number"
                value={props.value === undefined ? '' : props.value}
                onChange={e => {
                    const value = e.target.value.length > 0 ? parseInt(e.target.value) : undefined
                    props.onChange(value)
                }}
                isInvalid={!!error}
            />

            {!!error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
            {!!props.mru && <RecentList values={props.mru} value={props.value} onChange={props.onChange} />}

        </Form.Group >
    )
}
