import React from 'react'
import { CurrencyConverter } from '../lib/models'

import {
    Chart, ChartOptions,
    BarElement, PointElement, BarController, CategoryScale, LinearScale
} from 'chart.js'
import { ExchangeContext } from '../hooks/useExchange'

Chart.register(
    BarElement, PointElement, BarController, CategoryScale, LinearScale
)

export type ChartData = { labels: string[], values: (number | null)[] }
export class StyledChart extends React.Component<{ data: ChartData, theme?: 'dark' | 'light' }> {

    static contextType = ExchangeContext;
    context!: React.ContextType<typeof ExchangeContext>;
    chart = {} as Chart
    canvasRef!: HTMLCanvasElement | null

    componentDidMount() {
        const options = this.getChartOptions()
        this.chart = new Chart(this.canvasRef, {
            type: 'bar',
            data: {
                labels: this.props.data?.labels,
                datasets: [
                    {
                        data: this.props.data?.values,
                        yAxisID: 'yAxis',
                        xAxisID: 'xAxis',
                        backgroundColor: styles[this.props.theme ?? 'dark'].datasetBackgroundColor
                    },
                ],

            },
            options: options
        })
    }

    render() {
        if (this.chart.data) {
            this.chart.data.labels = this.props.data?.labels
            this.chart.data.datasets[0].data = this.props.data?.values
            this.chart.update()
        }
        return (
            <canvas ref={canvasRef => (this.canvasRef = canvasRef)} />
        )
    }

    getChartOptions() {
        const currencyConverter = (this.context.currencyConverter as CurrencyConverter)

        const chartOptions: ChartOptions<'bar'> = {
            responsive: true,
            plugins: {
                tooltip: {
                    enabled: false
                },
                legend: {
                    display: false
                },
            },
            scales: {
                yAxis: {
                    display: false,
                    beginAtZero: true
                },
                xAxis: {
                    ticks: {
                        color: styles[this.props.theme ?? 'dark'].axisText.color,
                        font: {
                            size: styles[this.props.theme ?? 'dark'].axisText.size
                        }
                    },
                    grid: {
                        borderColor: styles[this.props.theme ?? 'dark'].axisBorderColor,
                        display: false
                    }
                }
            },
            animation: {
                onComplete: (event) => {
                    /** hack to draw text next to the points on the chart after chart is shown */
                    event.chart.ctx.font = styles[this.props.theme ?? 'dark'].pointText.font
                    event.chart.ctx.fillStyle = styles[this.props.theme ?? 'dark'].pointText.color
                    event.chart.ctx.textAlign = 'center'
                    event.chart.getDatasetMeta(0).data.forEach((pointElement, index) => {
                        const pointData = event.chart.data.datasets[0].data[index]
                        if (typeof pointData === 'number') {
                            const currencyData = currencyConverter.format(pointData, { showCode: false })
                            if (pointElement.y < 10) /** point is too close to the top and text has to be offset to avoid cutoff */
                                event.chart.ctx.fillText(currencyData, pointElement.x, pointElement.y + 15)
                            else
                                event.chart.ctx.fillText(currencyData, pointElement.x, pointElement.y - 5)
                        }
                    })
                }
            }
        }
        return chartOptions
    }
}

const styles = {
    'dark': {
        datasetBackgroundColor: '#7f7f7f',
        pointText: { font: '8px Roboto', color: '#f2aa14' },
        axisText: { size: 10, color: '#ffffff' },
        axisBorderColor: '#ffffff20'
    },
    'light': {
        datasetBackgroundColor: '#fecd6790',
        pointText: { font: '8px Roboto', color: '#f2aa14' },
        axisText: { size: 10, color: '#00000090' },
        axisBorderColor: '#00000020'
    }
}