import { Currency } from './models'

export type CurrencyAmount = {
  readonly amount: number | null
  readonly currencyCode?: string
}

export function isCurrencyAmount(value: unknown): value is CurrencyAmount {
  return typeof value === 'object'
}

export function serializeCurrencyAmount(value: Readonly<CurrencyAmount>): string {
  const { amount, currencyCode } = value

  if (amount != null && currencyCode != null) {
    return `${amount} ${currencyCode}`
  }

  if (currencyCode != null) {
    return ' ' + currencyCode
  }

  if (amount != null) {
    return amount.toString()
  }

  return ''
}

export function deserializeCurrencyAmount(value: string | number): CurrencyAmount {
  if (typeof (value) === 'number') {
    return { amount: value, currencyCode: 'SAT' }
  }

  const separatorIndex = value.indexOf(' ')
  if (separatorIndex < 0) {
    const amount = parseFloat(value)
    return { amount, currencyCode: 'SAT' }
  }
  else {
    const amount = parseFloat(value.substring(0, separatorIndex))
    const currencyCode = value.substring(separatorIndex + 1)
    return { amount, currencyCode }
  }
}

const CURRENCY_FORMATTER_OPTIONS = Object.freeze({ showCode: true })

export type CurrencyFormatterOptions = { showCode: boolean }

export type CurrencyFormatter = (value: number, options?: CurrencyFormatterOptions) => string

export function formatCurrency(currency: Currency, value: number, locale: string | undefined, options?: CurrencyFormatterOptions): string {
  options ??= CURRENCY_FORMATTER_OPTIONS

  if (currency.code === 'BTC') {
    return formatBTC(value, locale, options)
  }
  else {
    const roundedValue = roundDecimals(value, currency.decimals)
    if (roundedValue === 0) {
      return value.toLocaleString(locale) + (options.showCode ? ' ' + currency.code : '')
    }
    return roundedValue.toLocaleString(locale) + (options.showCode ? ' ' + currency.code : '')
  }
}

function formatBTC(value: number, locale: string | undefined, options: CurrencyFormatterOptions): string {
  let numberFormatOptions: Intl.NumberFormatOptions | undefined

  if (Number.isSafeInteger(value / 100000000)) {
    numberFormatOptions = undefined
  }
  else {
    numberFormatOptions =
    {
      maximumFractionDigits: 8,
      minimumFractionDigits: 8
    }
  }
  return value.toLocaleString(locale, numberFormatOptions) + (options?.showCode ? ' ' + 'BTC' : '')
}

export function roundDecimals(value: number, decimals: number) {
  const pow = Math.pow(10, decimals)
  value = Math.round(value * pow) / pow
  return value
}
