import React, { useEffect } from 'react'
import { ReactNode, useCallback, useState } from 'react'
import { Toast, ToastContainer } from '../lib/ui'

export type ToastService = {
  addToast: (content: ReactNode) => number
  removeToast: (id: number) => void
  // TODO: deprecate.
  disableToasts: (durationMs: number) => void
}

const ToastContext = React.createContext<ToastService | null>(null)
let id = 0

export default function useToast(): ToastService {
  const context = React.useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

export function ToastProvider({ children }: React.PropsWithChildren<unknown>) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [toasts, setToasts] = useState<ToastItemProps[]>([])

  const addToast = useCallback((content) => {
    const toast = { id: id++, children: content }
    if (!isDisabled) {
      setToasts(toasts => {
        if (toasts.some(t => t.children == toast.children)) {
          return toasts
        }
        return [...toasts, toast]
      })
    }
    return toast.id
  }, [setToasts, isDisabled])

  const removeToast = useCallback(id => {
    setToasts(toasts => toasts.filter(t => t.id !== id))
  }, [setToasts])

  const disableToasts = useCallback((durationMs: number) => {
    setIsDisabled(true)
    setTimeout(() => setIsDisabled(false), durationMs)
  }, [])

  return (
    <ToastContext.Provider value={{ addToast, removeToast, disableToasts }}>
      {children}
      <ToastContainer className="position-absolute bottom-0 end-0 p-2" style={{ zIndex: 5000 }}>
        {!isDisabled && toasts.map(({ id, children }) => (<ToastItem key={id} id={id}>{children}</ToastItem>))}
      </ToastContainer>
    </ToastContext.Provider>
  )
}

type ToastItemProps = React.PropsWithChildren<unknown> & {
  id: number
}

function ToastItem({ id, children }: ToastItemProps) {
  const { removeToast } = useToast()

  useEffect(() => {
    const timer = setTimeout(() => removeToast(id), 3000)
    return () => clearTimeout(timer)
  }, [id, removeToast])

  return (
    <Toast className="bg-dark border border-primary">
      <Toast.Body className="">{children}</Toast.Body>
    </Toast>
  )
}
