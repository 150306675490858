import React from 'react'
import { useCurrency, useCurrencyConverter2, useCurrencyFormatter } from '../hooks/useExchange'

export default function CurrencyAmountText(props: {
    amount: number
    currencyCode?: string
    className?: string
}) {
    const currency = useCurrency(props.currencyCode)
    const formatter = useCurrencyFormatter(currency)
    const converter = useCurrencyConverter2()
    const amountString = formatter(converter.toCurrency(props.amount, currency.code))
    return (
        <span className={getClassName(props.className, props.amount)}>{amountString}</span>
    )
}

function getClassName(className: string | undefined, amount: number): string | undefined {
    if (!!className) {
        return className
    }

    if (amount > 0) {
        return 'text-nowrap text-success fw-bold'
    } else {
        return 'text-nowrap'
    }
}